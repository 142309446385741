import React, { useEffect, useRef, useState } from 'react';
import attachment from '../../assets/images/attachment.svg';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import LeftMessage from './leftMessageCard';
import RightMessage from './rightMessagingCard';
import {
  dynamicMessagePayloadWithAttachment,
  getUserData,
  isDocsType,
  isExcelType,
  isImageType,
  isPdfType,
  placeholderBasedOnFileType,
} from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';

export const MessagingContainerForMessageGroup = props => {
  const messageInputRef = useRef();
  const { type, subjectId, selectedTabGroup, selectedThreadData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFileType, setSelectedFileType] = useState('message');
  const { docURL } = useSelector(state => state.common);
  const [selectedImageFile, setSelectedImageFile] = useState('');
  const { threadBySubjectId, imageUrlFromDB, isImageUploadingToDBLoading, isPostMessageLoading } =
    useSelector(state => state.requests);
  const dispatch = useDispatch();
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      messageInputRef?.current?.focus();
    }, 3000);
    return () => clearTimeout(timer);
  }, [
    threadBySubjectId?.messages?.length,
    messageInputRef?.current?.value,
    isPostMessageLoading,
    isLoading,
    messagesEndRef.current,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedTabGroup !== '' && !isNaN(subjectId)) {
        dispatch({ type: 'GET_THREAD_BY_SUBJECT_ID', id: subjectId });
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [selectedTabGroup, threadBySubjectId]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [selectedTabGroup]);

  const handleImageUpload = info => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      if (isImageType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('image');
        }, 10);

        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_IMAGE_TO_DB', formData: formData });
      } else if (isPdfType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('pdf');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else if (isExcelType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('excel');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else if (isDocsType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('docs');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else {
        setSelectedFileType('message');
      }
    }
  };

  const onMessageSend = e => {
    if (selectedFileType !== 'message' && !messageInputRef?.current?.value) {
      dispatch({
        type: 'POST_MESSAGE',
        formData: {
          id: threadBySubjectId?.id,
          message: dynamicMessagePayloadWithAttachment(selectedFileType, imageUrlFromDB, docURL),
        },
      });
      setSelectedFileType('message');
      setSelectedImageFile('');
    } else if (
      threadBySubjectId?.messages?.length &&
      selectedFileType === 'message' &&
      messageInputRef?.current?.value !== ''
    ) {
      dispatch({
        type: 'POST_MESSAGE',
        formData: {
          id: threadBySubjectId?.id,
          message: {
            message: messageInputRef.current.value,
            has_attachment: 0,
          },
        },
      });
      messageInputRef.current.value = '';
      setSelectedImageFile('');
    }
  };

  function format(val) {
    return moment(val).format('YYYYMMDD') !== moment().format('YYYYMMDD')
      ? moment(val).format('DD MMMM')
      : 'Today';
  }

  return (
    <div style={{ backgroundColor: 'white', border: 'none' }} className="message-body">
      {subjectId ? (
        <div style={{ backgroundColor: 'white' }} className="request-message-wrapper">
          <div className="request-message-details">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: (height * 58) / 100,
                overflowY: 'auto',
              }}
              className="message-area"
            >
              {isLoading ? (
                <div
                  style={{ height: (height * 55) / 100, backgroundColor: 'white' }}
                  className="d-flex-a-c"
                >
                  <Spin />
                </div>
              ) : (
                selectedThreadData[0]?.messages?.map((m, i) => {
                  const prevMessage = selectedThreadData[0]?.messages[i - 1];
                  const prevMessageTimeStamp = format(prevMessage?.created_at);
                  const currentMessageTimeStamp = format(m?.created_at);
                  if (getUserData().id === m?.user_id) {
                    if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                      return (
                        <>
                          {format(m?.created_at) && (
                            <div className="date">
                              <div className="message-container-date-box">
                                <p className="message-container-date-box-p">
                                  {format(m?.created_at)}
                                </p>
                              </div>
                            </div>
                          )}
                          <RightMessage m={m} />
                        </>
                      );
                    } else {
                      return <RightMessage m={m} />;
                    }
                  }
                  if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                    return (
                      <>
                        {format(m?.created_at) && (
                          <div className="date">
                            <div className="message-container-date-box">
                              <p className="message-container-date-box-p">
                                {format(m?.created_at)}
                              </p>
                            </div>
                          </div>
                        )}
                        <LeftMessage m={m} type="group" selectedThreadData={selectedThreadData} />
                      </>
                    );
                  } else {
                    return (
                      <LeftMessage m={m} type="group" selectedThreadData={selectedThreadData} />
                    );
                  }
                })
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className="text-box">
              <div className="icon-write">
                {threadBySubjectId?.messages?.length && (
                  <div className="icon">
                    <Upload
                      name="attachment"
                      accept="image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                      onChange={handleImageUpload}
                      showUploadList={false}
                      maxCount={1}
                      customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
                    >
                      <img src={attachment} alt="" />
                    </Upload>
                  </div>
                )}
                <div style={{ width: '100%' }}>
                  {selectedImageFile ? (
                    <div
                      style={{
                        backgroundColor: '#eef1f4',
                        gap: '15px',
                        width: 'max-content',
                        padding: '10px 5px',
                        borderRadius: '8px',
                        alignItems: 'center',
                      }}
                      className="d-flex"
                    >
                      <div>
                        <img
                          height={25}
                          width={25}
                          src={placeholderBasedOnFileType(selectedFileType)}
                          alt=""
                        />
                      </div>
                      <div>{selectedImageFile}</div>
                      <Button
                        style={{ border: 'none', backgroundColor: 'transparent' }}
                        icon={<CloseCircleOutlined />}
                        size="small"
                        onClick={() => {
                          setSelectedImageFile(null);
                        }}
                      />
                    </div>
                  ) : (
                    <input
                      autoFocus={true}
                      ref={messageInputRef}
                      required
                      className="write"
                      type="text"
                      placeholder="Write a message..."
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          onMessageSend(e);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <Button
                onClick={e => onMessageSend(e)}
                loading={isImageUploadingToDBLoading}
                className="send"
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      ) : type === 'detail' ? (
        <div
          style={{
            height: (height * 80) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <div
          style={{
            height: (height * 63) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          Please select one of the chatroom.
        </div>
      )}
    </div>
  );
};
