import { put, takeLatest, call } from 'redux-saga/effects';
import { message } from 'antd';
//Import API Service.
import { getRequestAPI, postRequestAPI, putRequestAPI } from '../../helpers/service';

import allExperts from './mockdata/exper.json';

function* getExpertDegree({ formData }) {
  try {
    const response = yield call(putRequestAPI, { url: 'university-degrees', data: formData });

    if (response.status === 200 || response.status === 201) {
      if (response?.data?.data?.length) {
        yield put({ type: 'EXPERT_DEGREE_SUCCESS', data: response?.data?.data || [] });
      } else {
        yield put({ type: 'EXPERT_DEGREE_SUCCESS', data: [] });
      }
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERT_DEGREE_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'EXPERT_DEGREE_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getExpertCertification({ formData }) {
  try {
    const response = yield call(putRequestAPI, {
      url: `licenses-and-certificates`,
      data: formData,
    });

    if (response.status === 200 || response.status === 201) {
      if (response?.data?.data?.length) {
        yield put({ type: 'EXPERT_CERTIFICATION_SUCCESS', data: response?.data?.data || [] });
      } else {
        yield put({ type: 'EXPERT_CERTIFICATION_SUCCESS', data: [] });
      }
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERT_CERTIFICATION_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'EXPERT_CERTIFICATION_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getExpertExperince({ formData }) {
  try {
    const response = yield call(putRequestAPI, { url: `experience-in-industry`, data: formData });

    if (response.status === 200 || response.status === 201) {
      if (response?.data?.data?.length) {
        yield put({ type: 'EXPERT_EXPERINCE_SUCCESS', data: response?.data?.data || [] });
      } else {
        yield put({ type: 'EXPERT_EXPERINCE_SUCCESS', data: [] });
      }
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERT_EXPERINCE_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'EXPERT_EXPERINCE_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getExpertDetails({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `expert/${id}`, params: {} });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'EXPERT_DETAIL_SUCCESS', data: response?.data[0] || {} });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERT_DETAIL_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'EXPERT_DETAIL_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* assignQuestionToExpert({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `assign-expert`, data: formData });
    if (response.status === 200 || response.status === 201) {
      if (response?.data?.data?.length) {
        yield put({ type: 'ASSIGN_QUESTION_TO_EXPERT_SUCCESS', data: response?.data || [] });
      } else {
        yield put({ type: 'ASSIGN_QUESTION_TO_EXPERT_SUCCESS', data: [] });
      }
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'ASSIGN_QUESTION_TO_EXPERT_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'ASSIGN_QUESTION_TO_EXPERT_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getAllSubCategoryForExpert({ formData }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `sub-categories`,
      data: { parent_id: 0, per_page: 1000 },
    });

    if (response.status === 200) {
      yield put({ type: 'EXPERT_ALL_SUB_CATEGORY_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'EXPERT_ALL_SUB_CATEGORY_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'EXPERT_ALL_SUB_CATEGORY_ERROR', error: error.message || '' });
  }
}

function* getExperinceTypeList() {
  try {
    const response = yield call(getRequestAPI, { url: `service-experience-level`, params: {} });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'EXPERINCE_TYPE_LIST_SUCCESS', data: response?.data?.data || {} });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERINCE_TYPE_LIST_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'EXPERINCE_TYPE_LIST_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getRateTypeList() {
  try {
    const response = yield call(getRequestAPI, { url: `rate-types`, params: {} });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'RATE_TYPE_LIST_SUCCESS', data: response?.data?.data || {} });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'RATE_TYPE_LIST_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'RATE_TYPE_LIST_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getMainSubCategoryList({ formData }) {
  try {
    const response = yield call(putRequestAPI, { url: `services-and-rate`, data: formData });
    if (response.status === 200 || response.status === 201) {
      if (response?.data?.data?.length) {
        yield put({ type: 'EXPERT_MAINSUB_CAT_SUCCESS', data: response?.data?.data || [] });
      } else {
        yield put({ type: 'EXPERT_MAINSUB_CAT_SUCCESS', data: [] });
      }
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERT_MAINSUB_CAT_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'EXPERT_MAINSUB_CAT_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getAllExperts({ assign }) {
  try {
    const response = yield call(getRequestAPI, { url: `experts`, params: { assign } });
    // const response = { data: allExperts, status: 200 };
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'EXPERT_LIST_SUCCESS', data: response?.data?.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERT_LIST_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'EXPERT_LIST_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getRecommendedExperts({ questionId }) {
  try {
    const response = yield call(getRequestAPI, {
      url: `expert/recommendExpert/${questionId}`,
      params: {},
    });

    if (response.status === 200 || response.status === 201) {
      if (response?.data?.length === 0)
        message.error({
          content: 'No recommended experts were found',
          className: 'error-class',
          duration: 3,
        });
      yield put({ type: 'RECOMMENDED_EXPERT_LIST_SUCCESS', data: response?.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'RECOMMENDED_EXPERT_LIST_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'RECOMMENDED_EXPERT_LIST_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getRecommendationText({ userId, questionId }) {
  try {
    const response = yield call(getRequestAPI, {
      url: `expert/generate-proposal/${userId}/${questionId}`,
      params: {},
    });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'RECOMMENDATION_TEXT_SUCCESS', data: response?.data?.generatedText || '' });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'RECOMMENDATION_TEXT_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'RECOMMENDATION_TEXT_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getALlBillPans() {
  try {
    const response = yield call(getRequestAPI, { url: `billing-plan`, params: {} });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'BILL_PLAN_SUCCESS', data: response?.data?.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'BILL_PLAN_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'BILL_PLAN_ERROR', error: error?.message || 'Something went wrong' });
  }
}
function* getQuotationRequest() {
  try {
    // const response = yield call(getRequestAPI, { url: `billing-plan`, params: {} });
    const response = {
      data: {
        data: {
          id: 2,
          assignment_title: 'this is test assignment',
          details: 'this is test assignment description',
          question_id: 19,
          price: '36.00',
          billing_plan_id: 2,
          delivery_date: '2023-03-31',
          subscription_id: null,
          confirmed: 0,
          created_at: '2023-03-12T10:10:53.000000Z',
          updated_at: '2023-03-12T10:27:20.000000Z',
        },
      },
      status: 200,
    };
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_QUOTATION_SUCCESS', data: response?.data?.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'GET_QUOTATION_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'GET_QUOTATION_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getPaymentData({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `payments/question`, data: formData });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'PAYMENTS_DATA_SUCCESS', data: response?.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'PAYMENTS_DATA_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'PAYMENTS_DATA_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* creditRequest({ formData }) {
  try {
    const response = yield call(getRequestAPI, { url: `credits`, data: formData });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'ALL_CREDIT_SUCCESS', data: response?.data?.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'ALL_CREDIT_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'ALL_CREDIT_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* paymentRequest({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `order`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'PAYMENT_SUCCESS', data: response.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'PAYMENT_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'PAYMENT_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* paymentTaxRequest({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `order`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'PAYMENT_TAX_SUCCESS', data: response.data || [] });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'PAYMENT_TAX_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'PAYMENT_TAX_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getCreditById({ id }) {
  try {
    const response = yield call(postRequestAPI, { url: `credit/${id}`, data: {} });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_CREDIT_BY_ID_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_CREDIT_BY_ID_ERROR', error: response });
    }
  } catch (error) {
    yield put({ type: 'GET_CREDIT_BY_ID_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getEarningsById({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `payments/user`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_EARNING_BY_ID_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_EARNING_BY_ID_ERROR', error: response });
    }
  } catch (error) {
    yield put({ type: 'GET_EARNING_BY_ID_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getExpertNewApplicants() {
  try {
    const response = yield call(getRequestAPI, { url: `experts/new`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'EXPERT_NEW_APPLICATION_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'EXPERT_NEW_APPLICATION_ERROR', data: '' });
    }
  } catch (error) {
    yield put({ type: 'EXPERT_NEW_APPLICATION_ERROR' });
  }
}

function* getExpertPendingUpdates() {
  try {
    const response = yield call(getRequestAPI, { url: `experts/pending-update`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'EXPERT_PENDING_UPDATES_SUCCESS', data: response.data.data || [] });
    } else {
      yield put({ type: 'EXPERT_PENDING_UPDATES_ERROR', data: '' });
    }
  } catch (error) {
    yield put({ type: 'EXPERT_PENDING_UPDATES_ERROR' });
  }
}

function* updateExpertStatus({ formData }) {
  try {
    const response = yield call(putRequestAPI, { url: `update-expert-status`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'UPDATE_EXPERT_STATUS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'UPDATE_EXPERT_STATUS_ERROR', data: '' });
    }
  } catch (error) {
    yield put({ type: 'UPDATE_EXPERT_STATUS_ERROR' });
  }
}

function* updateExpertFreezingStatus({ expert_id, formData, handleRefresh }) {
  try {
    const response = yield call(putRequestAPI, {
      url: `update-expert-freezing-status/${expert_id}`,
      data: formData,
    });
    if (response.status === 201) {
      if (handleRefresh) {
        handleRefresh();
      }
      yield put({ type: 'UPDATE_EXPERT_FREEZING_STATUS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'UPDATE_EXPERT_FREEZING_STATUS_ERROR', data: '' });
    }
  } catch (error) {
    yield put({ type: 'UPDATE_EXPERT_FREEZING_STATUS_ERROR' });
  }
}

function* watchExpertSagaRequests() {
  yield takeLatest('EXPERT_DEGREE_REQUEST', getExpertDegree);
  yield takeLatest('EXPERT_CERTIFICATION_REQUEST', getExpertCertification);
  yield takeLatest('EXPERT_EXPERINCE_REQUEST', getExpertExperince);
  yield takeLatest('EXPERT_DETAIL_REQUEST', getExpertDetails);
  yield takeLatest('EXPERT_ALL_SUB_CATEGORY_REQUEST', getAllSubCategoryForExpert);
  yield takeLatest('EXPERINCE_TYPE_LIST_REQUEST', getExperinceTypeList);
  yield takeLatest('RATE_TYPE_LIST_REQUEST', getRateTypeList);
  yield takeLatest('EXPERT_MAINSUB_CAT_REQUEST', getMainSubCategoryList);
  yield takeLatest('EXPERT_LIST_REQUEST', getAllExperts);
  yield takeLatest('RECOMMENDED_EXPERT_LIST_REQUEST', getRecommendedExperts);
  yield takeLatest('RECOMMENDATION_TEXT_REQUEST', getRecommendationText);
  yield takeLatest('BILL_PLAN_REQUEST', getALlBillPans);
  yield takeLatest('GET_QUOTATION_REQUEST', getQuotationRequest);
  yield takeLatest('PAYMENTS_DATA_REQUEST', getPaymentData);

  yield takeLatest('ASSIGN_QUESTION_TO_EXPERT', assignQuestionToExpert);
  yield takeLatest('ALL_CREDIT_REQUEST', creditRequest);
  yield takeLatest('PAYMENT_REQUEST', paymentRequest);
  yield takeLatest('PAYMENT_TAX_REQUEST', paymentTaxRequest);
  yield takeLatest('GET_CREDIT_BY_ID', getCreditById);
  yield takeLatest('GET_EARNING_BY_ID', getEarningsById);
  yield takeLatest('EXPERT_NEW_APPLICATION', getExpertNewApplicants);
  yield takeLatest('EXPERT_PENDING_UPDATES', getExpertPendingUpdates);
  yield takeLatest('UPDATE_EXPERT_STATUS', updateExpertStatus);
  yield takeLatest('UPDATE_EXPERT_FREEZING_STATUS', updateExpertFreezingStatus);
}

function* ExpertSaga() {
  yield watchExpertSagaRequests();
}

export default ExpertSaga;
