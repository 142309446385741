import React, { useState } from 'react';
import { Layout, Row, Col, Modal } from 'antd';
import MainLogo from '../../assets/images/black-logo.svg';
import { AiOutlineTwitter, AiFillLinkedin } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { SOCIAL } from '../../helpers/constant';
import { useLocation } from 'react-router-dom';
const { Footer } = Layout;

const PublicFooter = props => {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const [modalVisible, setModalVisible] = useState(false);
  const [ispaused, setIsPaused] = useState(false);

  const location = useLocation();
  return (
    <Footer className="public-footer">
      <Row gutter={35}>
        <Col xs={9} sm={9} md={9} lg={9} xl={9} className="">
          <img src={MainLogo} alt="Accountizer Logo "></img>
          <p className="mt20 footer-text">© 2023 Accountizer, Inc. All rights reserved.</p>
          <div>
            <span className="mr10">
              <AiOutlineTwitter
                onClick={() => window.open(SOCIAL?.twitter?.page, '_blank')}
                className="cursor-p"
                size={22}
                color="#6B7280"
              />
            </span>
            <span className="mr10">
              <RiFacebookFill
                onClick={() => window.open(SOCIAL?.facebook?.page, '_blank')}
                className="cursor-p"
                size={22}
                color="#6B7280"
              />
            </span>
            <span>
              <AiFillLinkedin
                onClick={() => window.open(SOCIAL?.linkedIn?.page, '_blank')}
                className="cursor-p"
                size={22}
                color="#6B7280"
              />
            </span>
          </div>
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} className="">
          <p className="footer-label">ACCOUNTIZER</p>
          <p>
            <Link to={'/find-expert'}>Find a professional</Link>
          </p>
          <p>
            <Link to={'/about-us'}>About Accountizer</Link>
          </p>
          <p>
            <Link to={`${location.pathname === '/expert' ? '/expert/faq' : '/faq'}`}>FAQs</Link>
          </p>
          <p>
            <Link to={'/contact'}>Contact</Link>
          </p>
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} className="footer-contact">
          <p className="footer-label">For Experts</p>
          <p>
            <Link to={'/expert'}>Become an Expert</Link>
          </p>
          {location.pathname === '/expert' && (
            <p
              style={{ cursor: 'pointer', fontSize: '16px', fontWeight: '400', color: '#6B7280' }}
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setModalVisible(true);
                }, [100]);
              }}
            >
              Why to join us ?
            </p>
          )}
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} className="footer-contact">
          <p className="footer-label">Contact us</p>
          {/* <p></p> */}
          <p>admin@accountizer.ca</p>
          <p>
            347 Jane St, Toronto,<br></br>Ontario, Canada, M6S3Z3
          </p>
        </Col>
        <Modal
          open={modalVisible}
          width={(width * 68) / 100}
          style={{ height: (height * 75.5) / 100 }}
          footer={null}
          onCancel={() => {
            setIsPaused(true);
            setTimeout(() => {
              setModalVisible(false);
            }, [100]);
          }}
          bodyStyle={{ padding: 0 }}
          centered={true}
        >
          <ReactPlayer
            className="react-vimeo-player-modal"
            url="https://vimeo.com/808442055"
            controls={true}
            stopOnUnmount={true}
            playing={!ispaused}
            width={(width * 65) / 100}
            height={(height * 75.5) / 100}
          />
        </Modal>
      </Row>
    </Footer>
  );
};

export default PublicFooter;
