import ReactLinkify from 'react-linkify';

export const SignupCaution = () => {
  return (
    <strong>
      By clicking 'Sign Up', you confirm that you received this URL directly from the Accountizer
      team or their affiliated business incubator. This web app prototype is strictly for
      demonstration and trial purposes, and we are not liable for its use. By proceeding, you agree
      to maintain confidentiality and not disclose any proprietary information, techniques, or
      business secrets you may encounter. For further information, contact:{' '}
      <ReactLinkify>admin@accountizer.ca</ReactLinkify>.
    </strong>
  );
};
