import { AddAnotherCard } from '../../common/addAnotherCard';
import { getUserData } from '../../../helpers/functions';
import { CloseOutlined } from '@ant-design/icons';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { ExpertSignUpSuccessModal } from '../../common/modal/expertSignUpSuccessModal';
import { message } from 'antd';

import {
  Card,
  Col,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Select,
  Spin,
} from 'antd';
import CustomSelect from '../../common/customSelect';
const { TextArea } = Input;

const Experience = ({ handleNext, dependent = '', handleBack }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { eid } = useParams();
  const [experienceHtml, setExperienceHtml] = useState('');
  const { isCertificateUpdating, certiMsg } = useSelector(state => state.steps);
  const { isExperinceLoading, ExperinceList, isExperinceEmpty } = useSelector(
    state => state.expert,
  );
  const { isIndustryLoading, industryList } = useSelector(state => state.common);
  const { isAdminDeleteExperienceLoading } = useSelector(state => state.admin);
  const [tempExperience, setTempExperience] = useState([]);
  const [industrySubCategoryOptions, setIndustrySubCategoryOptions] = useState({});
  const [visibleModal, setVisibleModal] = useState(false);

  const optionsList = useCallback(items => {
    const options =
      items?.map(item => ({
        value: item.id,
        label: item.name,
      })) ?? [];
    return options;
  }, []);

  useEffect(() => {
    const getExpertExperiences = () => {
      dispatch({
        type: 'EXPERT_EXPERINCE_REQUEST',
        formData: { expert_id: eid || getUserData().id },
      });
    };

    const getIndustryList = () => {
      dispatch({ type: 'GET_INDUSTRY_REQUEST', formData: { expert_id: eid || getUserData().id } });
    };

    getExpertExperiences();
    getIndustryList();
  }, [eid, dispatch, isAdminDeleteExperienceLoading, isCertificateUpdating, certiMsg]);

  const filterIndustrySubCategory = useCallback(
    parentId => {
      return industryList?.filter(item => item?.parent_id === parentId);
    },
    [industryList],
  );

  useEffect(() => {
    if (!isExperinceLoading && isExperinceEmpty !== undefined) {
      let experiences;
      if (ExperinceList.length === 0) {
        experiences = [
          {
            expert_id: eid || getUserData().id,
            industry_id: '',
            industry_sub_category_ids: [],
            number_of_years: 0,
            number_of_months: 0,
            experience_gained: '',
            privately_owned_business: false,
            publicly_accountable_business: false,
          },
        ];
      } else if (ExperinceList.length > 0) {
        experiences = ExperinceList.map(item => ({
          ...item,
          privately_owned_business: item?.privately_owned_business === 1,
          publicly_accountable_business: item?.publicly_accountable_business === 1,
          industry_id: item?.industry?.id ?? '',
          industry_sub_category_ids: item?.industry_sub_categories?.map(sub => sub.id) ?? [],
        }));
      }
      setTempExperience({
        experiences,
      });

      setIndustrySubCategoryOptions(
        ExperinceList.reduce((acc, item, index) => {
          acc[index] = optionsList(filterIndustrySubCategory(item?.industry?.id ?? ''));
          return acc;
        }, {}),
      );
    }
  }, [
    isExperinceLoading,
    ExperinceList,
    isExperinceEmpty,
    eid,
    filterIndustrySubCategory,
    optionsList,
  ]);

  useEffect(() => {
    if (!isCertificateUpdating && certiMsg === 'success') {
      // handleNext('mainSubCategories');
      if (eid) {
        navigate(`/application-detail/${eid}`);
        message.success('Expert profile updated successfully');
      } else {
        setVisibleModal(true);
      }
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isCertificateUpdating, certiMsg, handleNext, dispatch]);

  useEffect(() => {
    const onFinish = values => {
      const { experiences } = values;
      const data = experiences.map(item => ({
        ...Object.keys(item).reduce(
          (acc, key) => {
            if (item[key] != null && item[key] !== '') {
              acc[key] = item[key];
            }
            return acc;
          },
          {
            expert_id: eid || getUserData().id,
          },
        ),
      }));

      if (data.length === 0) {
        return;
      }

      dispatch({
        type: 'EXPERT_CERTIFICATE_UPDATE_REQUEST',
        formData: data,
        moduleType: 'experince',
      });
    };

    const handleExperienceDelete = (field, id, clientRemove) => {
      if (id) {
        dispatch({
          type: 'ADMIN_DELETE_EXPERIENCE',
          id,
        });
      }
      clientRemove(field.name);
    };

    const handleIndustryChange = (value, name) => {
      // Update the subcategory options based on the selected category
      const newSubCategoryOptions = filterIndustrySubCategory(value);
      setIndustrySubCategoryOptions(prevOptions => {
        const newOptions = { ...prevOptions };
        newOptions[name] = optionsList(newSubCategoryOptions);
        return newOptions;
      });

      form.setFieldValue(['experiences', name, 'industry_sub_category_ids'], []);
    };

    const loadExperiences = () => {
      return (
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          initialValues={tempExperience}
        >
          <Form.List name="experiences">
            {(fields, { add, remove }) => (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {fields.map(field => (
                  <div key={field.name} className="certificate-section">
                    <Row gutter={30} className={'mb20'}>
                      {fields.length > 1 && (
                        <div className="remove-section-icon-wrapper">
                          <CloseOutlined
                            onClick={() =>
                              handleExperienceDelete(
                                field,
                                form.getFieldValue(['experiences', field.name, 'id']),
                                remove,
                              )
                            }
                            className="cursor-p"
                          />
                        </div>
                      )}
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Industry"
                          name={[field.name, 'industry_id']}
                          className="form-field"
                          rules={[{ required: true, message: 'Please select industry' }]}
                        >
                          <CustomSelect
                            placeholder="Select an option"
                            // ref={i === certi.length - 1 ? inputRef : undefined}
                            style={{
                              width: '100%',
                            }}
                            size="large"
                            showSearch
                            allowClear
                            loading={isIndustryLoading}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={optionsList(industryList?.filter(item => !item.parent_id))}
                            onChange={value => handleIndustryChange(value, field.name)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Industry Sub Categorie(s)"
                          name={[field.name, 'industry_sub_category_ids']}
                          className="form-field"
                        >
                          <Select
                            mode="multiple"
                            style={{
                              width: '100%',
                            }}
                            size="large"
                            showSearch
                            allowClear
                            loading={isIndustryLoading}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={industrySubCategoryOptions[field.name]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="No. years"
                          name={[field.name, 'number_of_years']}
                          rules={[
                            {
                              message: 'Please enter number of year',
                              required: true,
                            },
                            {
                              pattern: new RegExp(/^[0-9]{1,2}$/),
                              message: 'Please enter valid year',
                            },
                          ]}
                        >
                          <InputNumber
                            onWheel={e => e.target.blur()}
                            max={99}
                            size="large"
                            type="number"
                            placeholder="No. years"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="No. month"
                          name={[field.name, 'number_of_months']}
                          rules={[
                            {
                              message: 'Please enter number of month',
                              required: true,
                            },
                            {
                              pattern: new RegExp(/^[0-9]{1,2}$/),
                              message: 'Please enter valid month',
                            },
                          ]}
                        >
                          <InputNumber
                            onWheel={e => e.target.blur()}
                            max={11}
                            size="large"
                            type="number"
                            placeholder="No. month"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label="Experience gained while working"
                          name={[field.name, 'experience_gained']}
                          rules={[
                            {
                              message: 'Please enter experince detail while working',
                              required: true,
                            },
                            {
                              max: 5000,
                              message: 'Maximum 5000 characters are allowed',
                            },
                          ]}
                        >
                          <TextArea
                            size="large"
                            type="number"
                            rows={4}
                            placeholder="Enter experince here..."
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          valuePropName="checked"
                          name={[field.name, 'privately_owned_business']}
                        >
                          <Checkbox>Privately owned business</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          valuePropName="checked"
                          name={[field.name, 'publicly_accountable_business']}
                        >
                          <Checkbox>Publicly accountable business</Checkbox>
                        </Form.Item>
                      </Col> */}
                      <Form.Item
                        style={{ display: 'none' }}
                        name={[field.name, 'id']}
                        className="form-field"
                      >
                        <Input />
                      </Form.Item>
                    </Row>
                  </div>
                ))}
                <AddAnotherCard handleClick={add} text={'add another experience'} />
              </Col>
            )}
          </Form.List>
          <Divider className="mt40 mb40" />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              className="mt5"
              disabled={isCertificateUpdating}
              loading={isCertificateUpdating}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      );
    };

    if (!tempExperience?.experiences?.length) {
      setExperienceHtml(null);
    }

    setExperienceHtml(loadExperiences());
  }, [
    tempExperience,
    form,
    eid,
    dispatch,
    isIndustryLoading,
    industryList,
    isCertificateUpdating,
    optionsList,
    industrySubCategoryOptions,
    filterIndustrySubCategory,
  ]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isIndustryLoading && <Spin />}
          {!isIndustryLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack('experience')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>I have experience in this/these industry(ies)</span>
              </p>
              {experienceHtml}
            </>
          )}
        </Card>
      </div>
      {visibleModal && (
        <ExpertSignUpSuccessModal
          visible={visibleModal}
          setVisible={setVisibleModal}
          navigateTo="/dashboard/expert/request"
        />
      )}
    </div>
  );
};
export default Experience;
