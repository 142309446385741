import React, { useEffect } from 'react';
import { Layout, Popover, Avatar } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { logout, getFullName, showAvatarName } from '../../helpers/functions';
import MainLogo from '../../assets/images/black-logo.svg';
const { Header } = Layout;

const Headers = props => {
  // hide header in print mode
  const { print } = useParams();
  const navigate = useNavigate();

  const onLogoutClick = () => {
    logout();
    navigate('/login');
  };

  const content = (
    <div>
      <p>{getFullName()}</p>
      <p>
        <span className="header-menu pointer" onClick={() => onLogoutClick()}>
          Logout
        </span>
      </p>
    </div>
  );

  return (
    <>
      {!!print || (
        <Header className="header">
          <div className="d-flex-between">
            <div className="main-logo">
              <img
                src={MainLogo}
                onClick={() => navigate('/')}
                className="pointer"
                alt="Accountizer Logo "
              ></img>
            </div>
            <div className="header-menu d-flex-a-center">
              <Popover placement="bottom" content={content} trigger="hover">
                <Avatar size="large" className="pointer">
                  {showAvatarName()}
                </Avatar>
              </Popover>
            </div>
          </div>
        </Header>
      )}
    </>
  );
};

export default Headers;
