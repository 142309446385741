/* eslint-disable react-hooks/exhaustive-deps */
import { Radio, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RequestCard from '../common/requestCard';
import { AssignedToExpertCard } from '../common/assignedToExpertCard';
import { ProcessHistoryStepsCard } from '../common/processHistoryStepsCard';
import { MessagingContainer } from '../common/messagingContainer';
import PaymentDetails from './paymentDetails';
import ReactLinkify from 'react-linkify';
import { AdjustIconForNavigation } from '../../assets/images/components/AdjustIconForNavigation';
import { ProfileIconForNavigation } from '../../assets/images/components/ProfileIconForNavigation';
import { PaymentIconForNavigation } from '../../assets/images/components/PaymentIconForNavigation';
import { ArchiveIconForNavigation } from '../../assets/images/components/ArchiveForNavigation';
import { ArchiveSection } from './archiveSection';
import { getSubCategoryName } from '../../helpers/functions';
import EvaluationReportModal from '../common/modal/EvaluationReportModal';
import WhatsNextModal from '../common/modal/WhatsNextModal';

const MyRequestDetail = () => {
  const [selectedTab, setSelectedTab] = useState('request');
  const dispatch = useDispatch();
  const { questionDetail, isQuestionLoading, isExpertRequestOrRejectRequestLoading } = useSelector(
    state => state.common,
  );
  const [visibleEvaluationModal, setVisibleEvaluationModal] = useState(false);
  const [visibleWhatsNextModal, setVisibleWhatsNextModal] = useState(false);
  const { requestQuestionStatus, isCreateNewThreadLoading, isAcceptOrRejectExpertRequestLoading } =
    useSelector(state => state.requests);
  const { isPayLoading } = useSelector(state => state.expert);

  const { isQuestionRequestStatusLoading } = useSelector(state => state.requests);
  const { isAssignQuestionLoading } = useSelector(state => state.expert);
  const { qid } = useParams();
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
      dispatch({ type: 'GET_QUESTION_REQUEST_STATUS', formData: { question_id: qid } });
    }
  }, [
    isAssignQuestionLoading,
    isCreateNewThreadLoading,
    isPayLoading,
    isAcceptOrRejectExpertRequestLoading,
    isExpertRequestOrRejectRequestLoading,
  ]);

  useEffect(() => {
    const showEvaluationReport = localStorage.getItem('showEvaluationReport');
    if (showEvaluationReport === 'true') {
      setVisibleEvaluationModal(true);
    }
  }, []);

  const isStatusAssigned = id => {
    if (id >= 3) {
      return true;
    }
    return false;
  };

  const renderUsingSwitch = () => {
    switch (selectedTab) {
      case 'request':
        return (
          <div className="request-details">
            <h3>Request Details</h3>
            <div className="request-details-wrapper">
              <h6>I need to contact an expert for a</h6>
              <div>
                <p>
                  {questionDetail?.question_type?.name ? questionDetail?.question_type?.name : '-'}
                </p>
                <div className="general">
                  <ReactLinkify>{questionDetail?.question_disc}</ReactLinkify>
                </div>
              </div>

              <div className="request-group">
                <div>
                  <h6>I am (a/an) or representing (a/an)</h6>
                  <p>
                    {questionDetail?.customer_type?.name
                      ? questionDetail?.customer_type?.name
                      : '-'}
                  </p>
                </div>
                <div>
                  <h6>Service category</h6>
                  <p>{questionDetail?.category?.name ? questionDetail?.category?.name : '-'}</p>
                </div>
                {!isStatusAssigned(questionDetail?.status?.question_status_id) && (
                  <div>
                    <h6>Type of service</h6>
                    <p>{getSubCategoryName(questionDetail)}</p>
                  </div>
                )}
                {isStatusAssigned(questionDetail?.status?.question_status_id) && (
                  <>
                    <div>
                      <h6>
                        {questionDetail?.category?.name
                          ? questionDetail?.category?.name + ' Subcategory'
                          : '-'}
                      </h6>
                      <p>{getSubCategoryName(questionDetail)}</p>
                    </div>
                    <div>
                      <h6>Business Sector</h6>
                      <p>{questionDetail?.sector?.name ? questionDetail?.sector?.name : '-'}</p>
                    </div>
                    {questionDetail?.invoices && (
                      <div>
                        <h6>How many invoices do you handle per month?</h6>
                        <p>
                          {questionDetail?.invoices?.name ? questionDetail?.invoices?.name : '-'}
                        </p>
                      </div>
                    )}
                  </>
                )}
                {!isStatusAssigned(questionDetail?.status?.question_status_id) && (
                  <div>
                    <h6>Estimated level of seniority required</h6>
                    <p>{questionDetail?.seniority?.name ? questionDetail?.seniority?.name : '-'}</p>
                  </div>
                )}

                {questionDetail?.ownership && (
                  <div>
                    <h6>Ownership type</h6>
                    <p>{questionDetail?.ownership?.name ? questionDetail?.ownership?.name : '-'}</p>
                  </div>
                )}
                <div>
                  <h6>Location</h6>
                  <p>{questionDetail?.location?.name ? questionDetail?.location?.name : '-'}</p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'messaging':
        return (
          <div className="request-message-details-with-border">
            <MessagingContainer
              subjectId={qid}
              expertId={questionDetail?.expert?.id ? questionDetail?.expert?.id : '-'}
              type="detail"
            />
          </div>
        );
      case 'payment':
        return (
          <div className="payment-detail">
            <PaymentDetails />
          </div>
        );
      case 'archive':
        return (
          <div className="archive-body">
            <ArchiveSection subjectId={qid} />
          </div>
        );
      default:
        return null;
    }
  };

  return !isQuestionLoading && !isQuestionRequestStatusLoading && !isPayLoading ? (
    <div className="request-details-body">
      <RequestCard navigateBackOnCancel={true} {...questionDetail} />

      <div className="request">
        <div className="request-wrapper">
          {isStatusAssigned(questionDetail?.status?.question_status_id) ||
          (questionDetail?.status?.question_status_id === 2 &&
            questionDetail?.assign_by_admin === 0) ? (
            <AssignedToExpertCard
              setSelectedTab={setSelectedTab}
              data={questionDetail}
              type={questionDetail?.status?.question_status_id === 10 && 'review'}
            />
          ) : null}
          {isStatusAssigned(questionDetail?.status?.question_status_id) && (
            <div>
              <Radio.Group
                className="request-tabs-navigator-container"
                buttonStyle="solid"
                defaultValue={selectedTab}
                onChange={e => setSelectedTab(e.target.value)}
                value={selectedTab}
              >
                <Radio.Button className="request-tabs-navigator-tabs" value="request">
                  <div className="request-tabs-navigator-tabs-div">
                    <ProfileIconForNavigation isActive={selectedTab === 'request'} />
                    Request Details
                  </div>
                </Radio.Button>
                <Radio.Button className="request-tabs-navigator-tabs" value="messaging">
                  <div className="request-tabs-navigator-tabs-div">
                    <AdjustIconForNavigation isActive={selectedTab === 'messaging'} />
                    Messaging
                  </div>
                </Radio.Button>
                <Radio.Button className="request-tabs-navigator-tabs" value="payment">
                  <div className="request-tabs-navigator-tabs-div">
                    <PaymentIconForNavigation isActive={selectedTab === 'payment'} />
                    Payment Details
                  </div>
                </Radio.Button>
                <Radio.Button className="request-tabs-navigator-tabs" value="archive">
                  <div className="request-tabs-navigator-tabs-div">
                    <ArchiveIconForNavigation isActive={selectedTab === 'archive'} />
                    Archive
                  </div>
                </Radio.Button>
              </Radio.Group>
            </div>
          )}
          <div className="request-container">{renderUsingSwitch()}</div>
        </div>
        <ProcessHistoryStepsCard
          rawData={questionDetail}
          showAssignedExpert={true}
          questionStatus={requestQuestionStatus}
        />
        {/* Evaluation Report Modal */}
        {questionDetail?.proposal && (
          <EvaluationReportModal
            questionDetail={questionDetail}
            visible={visibleEvaluationModal}
            setVisible={setVisibleEvaluationModal}
            handleWhatsNext={setVisibleWhatsNextModal}
          />
        )}

        {/* WhatsNext Modal */}
        {visibleWhatsNextModal && (
          <WhatsNextModal
            visible={visibleWhatsNextModal}
            setVisible={setVisibleWhatsNextModal}
            navigateTo={`/my-request/${questionDetail.id}`}
            expertName={`${questionDetail?.expert?.first_name} ${questionDetail?.expert?.last_name}`}
            userId={questionDetail?.user?.id}
          />
        )}
      </div>
    </div>
  ) : (
    <div style={{ height: (height * 60) / 100 }} className="d-flex-a-c">
      <Spin />
    </div>
  );
};

export default MyRequestDetail;
