import React, { useState } from 'react';
import { Layout, Button, Popover, Avatar } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import MainLogo from '../../assets/images/black-logo.svg';
import WhiteLogo from '../../assets/images/white-logo.svg';
import { ROLE_NAME } from '../../helpers/constant';
import { getFullName, isLogin, logout, role, showAvatarName } from '../../helpers/functions';
import ExpertHeader from './expertHeader';
const { Header } = Layout;

const items = [
  {
    label: 'Find an Expert',
    key: 'find-expert',
  },
  {
    label: 'About Accountizer',
    key: 'about-us',
  },
  {
    label: 'FAQs',
    key: 'faq',
  },
  {
    label: 'Contact',
    key: 'contact',
  },
];

const PublicHeader = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState('mail');
  const expertAvatar = localStorage.getItem('expertAvatar');

  const onClick = e => {
    if (e.key === 'about-us' || e.key === 'contact' || e.key === 'find-expert' || e.key === 'faq') {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    } else {
      setCurrent('');
    }
  };

  const onLogoutClick = () => {
    logout();
    navigate('/login');
  };
  const content = (
    <div>
      <p>{getFullName()}</p>
      <p
        className="pointer"
        onClick={() => {
          if (role() === ROLE_NAME.EXPERT) {
            navigate('/dashboard/expert/request');
          } else {
            navigate('/dashboard/customer');
          }
        }}
      >
        Dashboard
      </p>
      {role() === ROLE_NAME.EXPERT && !isLogin() && (
        <p
          onClick={() => {
            navigate('/expert');
          }}
        >
          Become an Expert
        </p>
      )}
      <p>
        <span className="header-menu pointer" onClick={() => onLogoutClick()}>
          Logout
        </span>
      </p>
    </div>
  );

  return (
    <>
      {location.pathname.includes('/expert') ? (
        <ExpertHeader />
      ) : (
        <Header
          className={`header ${location.pathname === '/' || location.pathname === '/expert' ? 'transfer' : ''}`}
        >
          <div
            className="d-flex-a-c"
            style={{ width: '100%', justifyContent: 'space-between', whiteSpace: 'nowrap' }}
          >
            <div>
              <div className="main-logo">
                {location.pathname === '/' || location.pathname === '/expert' ? (
                  <img
                    src={MainLogo}
                    onClick={() => navigate('/')}
                    className="pointer"
                    alt="Accountizer Logo "
                  ></img>
                ) : (
                  <img
                    src={MainLogo}
                    onClick={() => navigate('/')}
                    className="pointer"
                    alt="Accountizer Logo "
                  ></img>
                )}
              </div>
            </div>
            <div className="d-flex-a-c">
              <div className="header-menu public-header d-flex-a-c">
                <div className="d-flex-a-c">
                  {items.map((item, idx) => {
                    return (
                      <p key={idx} className="mb0" onClick={e => onClick(item)}>
                        <span className="cursor-p">{item.label}</span>
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              {isLogin() && (
                <div className="f-flex-a-e">
                  <div className="header-menu d-flex-a-center">
                    {expertAvatar ? (
                      <Popover placement="bottom" content={content} trigger="hover">
                        <img
                          style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                          src={expertAvatar}
                          alt=""
                        />
                      </Popover>
                    ) : (
                      <Popover placement="bottom" content={content} trigger="hover">
                        <Avatar size="large" className="pointer">
                          {showAvatarName()}
                        </Avatar>
                      </Popover>
                    )}
                  </div>
                </div>
              )}
              {!isLogin() && (
                <div className="d-flex-a-c">
                  <p
                    style={{ cursor: 'pointer' }}
                    className="mb0 mr15"
                    onClick={() => navigate('/login?type=customer')}
                  >
                    Client Login
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="float-r"
                    size="middle"
                    onClick={() => navigate('/expert')}
                  >
                    Become an expert
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Header>
      )}
    </>
  );
};

export default PublicHeader;
