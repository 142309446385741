import React from 'react';
import Helmet from '../../common/helmet';

const AdminBlogManagement = props => {
  return (
    <>
      <Helmet title="Expert Management" />
      <p>Blog management</p>
    </>
  );
};

export default AdminBlogManagement;
