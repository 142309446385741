import { Button, Input, Modal, Spin, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '../../../assets/images/CheckCircleBlueIcon.svg';
import SparkleIcon from '../../../assets/images/SparkleIcon.svg';
import expertIcon from '../../../assets/images/profile_placeholder.png';
import openAiIcon from '../../../assets/images/openai-icon.svg';
import TextArea from 'antd/es/input/TextArea';
import { createMarkup, role } from '../../../helpers/functions';
import { ROLE_NAME } from '../../../helpers/constant';

const { Search } = Input;
let filterParams = { search: '' };
export const AssignQuestionToExpertModal = props => {
  const { visible, setVisible, rowData, doRefresh, variant, onClose, onClickNeedHelp } = props;
  const {
    isExpertListLoading,
    isRecommendedExpertListLoading,
    expertsList,
    recommendedExpertsList,
    isRecommendationTextLoading,
    recommendationText,
  } = useSelector(state => state.expert);
  const [selectedExpertDetail, setSelectedExpertDetail] = useState();
  const [search, setSearch] = useState({ find: '' });
  const [filteredData, setFilteredData] = useState([]);
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const [showRecommendationModal, setShowRecommendationModal] = useState(false);
  const [recommendationTextValue, setRecommendationTextValue] = useState(
    variant === 'admin' ? 'Loading...' : '',
  );
  const isAdmin = role() === ROLE_NAME.ADMIN;
  const [isEditMode, setIsEditMode] = useState(true);
  const [showSameExpertModal, setShowSameExpertModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      dispatch({ type: 'EXPERT_LIST_REQUEST', assign: true });
      if (variant === 'client') {
        loadTopExperts();
      }
    }
    dispatch({ type: 'RECOMMENDED_EXPERT_LIST_CLEAR' });
  }, [visible]);

  useEffect(() => {
    if (showRecommendationModal) {
      dispatch({
        type: 'RECOMMENDATION_TEXT_REQUEST',
        userId: selectedExpertDetail.id,
        questionId: rowData?.id,
      });
    }
  }, [showRecommendationModal]);

  useEffect(() => {
    setRecommendationTextValue(recommendationText);
    if (recommendationTextValue !== 'Loading...') {
      setIsEditMode(false);
    }
  }, [recommendationText]);

  const loadTopExperts = () => {
    dispatch({ type: 'RECOMMENDED_EXPERT_LIST_REQUEST', questionId: rowData?.id });
  };

  useEffect(() => {
    if (expertsList?.length && search.find) {
      const filteredData = expertsList?.filter(el =>
        `${el.first_name} ${el.last_name}`.toLowerCase().includes(search?.find?.toLowerCase()),
      );
      setFilteredData(filteredData);
      if (!search.find.length) {
        setFilteredData(expertsList);
      }
    } else {
      setFilteredData(expertsList);
    }
  }, [search, filteredData, isExpertListLoading]);

  const handleSearch = e => {
    setSearch({ ...search, find: e.target.value });
  };

  const onSubmit = (proposalText = '') => {
    if (variant !== 'client') {
      dispatch({
        type: 'ASSIGN_QUESTION_TO_EXPERT',
        formData: {
          id: rowData?.id,
          expert_id: selectedExpertDetail.id,
          proposal_text: proposalText,
          assign_by_admin: variant === 'admin' ? 1 : 0,
        },
      });
    } else {
      dispatch({
        type: 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST',
        formData: {
          question_id: rowData?.id,
          is_recommended: 1,
          expert_id: selectedExpertDetail.id,
        },
      });
    }
    setVisible(false);
    setShowRecommendationModal(false);
    setSearch({ find: '' });
    doRefresh();
  };

  const generateAiMessage = () => {
    setShowRecommendationModal(true);
    setVisible(false);
  };

  const handleExpertSelection = data => {
    if (rowData.expert_id === data.id) {
      setShowSameExpertModal(true);
    } else {
      setSelectedExpertDetail(data);
    }
  };

  const expertItem = (data, idx) => (
    <div className="modal-body-expert-card" key={idx} onClick={() => handleExpertSelection(data)}>
      <div className="modal-body-expert-card-left-container">
        <div className="modal-body-expert-card-image-wrapper">
          <img src={data.avatar ? data.avatar : expertIcon} alt="" height={60} width={60} />
        </div>
        <div className="modal-body-expert-card-profile-wrapper">
          <div className="flex-x">
            <div className="modal-body-expert-card-profile-name">
              {data?.first_name} {data?.last_name}
            </div>
          </div>
          {data.total_rank ? <div>Total rank: {data.total_rank}</div> : null}
          <div
            style={{ fontSize: data?.category?.length > 3 ? '10px' : '14px' }}
            className="modal-body-expert-card-profile-category"
          >
            {data?.category?.map((el, i) => {
              return (
                <span key={i}>{data?.category?.length - 1 !== i ? `${el.name}, ` : el.name}</span>
              );
            })}
          </div>
        </div>
      </div>
      <div className="modal-body-expert-card-icon-wrapper">
        {selectedExpertDetail?.id === data.id && (
          <img src={CheckIcon} alt="" height={20} width={20} />
        )}

        <Button
          size="large"
          type="primary"
          className="ml-2"
          onClick={() => generateAiMessage(data)}
        >
          {isAdmin ? (
            <img src={openAiIcon} alt="Open AI" height={20} width={20} />
          ) : (
            <>
              <img src={SparkleIcon} alt="Open AI" height={20} width={20} />
              <span> Evaluation Report</span>
            </>
          )}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        closable={isAdmin ? false : true}
        centered
        footer={null}
        open={visible}
        width="600px"
        destroyOnClose={true}
        onCancel={() => {
          setVisible(false);
          onClose();
        }}
      >
        <div className="assign-to-expert-modal-container">
          <div className="assign-to-expert-modal-heading">
            {isAdmin ? 'Assign Question To Expert' : 'Top Matched Financial Professionals for You'}
          </div>
          <Row gutter={8}>
            <Col flex="auto">
              <Search
                defaultValue={filterParams['search']}
                size="large"
                allowClear
                placeholder="Search"
                style={{ width: '100%' }}
                enterButton
                onChange={handleSearch}
              />
            </Col>
            {isAdmin ? (
              <Col>
                <Button size="large" type="primary" onClick={loadTopExperts}>
                  Load top 3
                </Button>
              </Col>
            ) : null}
          </Row>

          {isRecommendedExpertListLoading ? (
            <div className="p-4 golden-experts-container modal-body">
              <div
                style={{
                  display: 'flex',
                  height: 'inherit',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin />
              </div>
            </div>
          ) : recommendedExpertsList.length ? (
            <div className="p-4 golden-experts-container modal-body">
              {recommendedExpertsList?.map((data, idx) => expertItem(data, idx))}
            </div>
          ) : null}

          <div
            style={{
              height: !recommendedExpertsList || recommendedExpertsList.length === 0 ? height : 300,
            }}
            className="modal-body"
          >
            {isExpertListLoading ? (
              <div
                style={{
                  display: 'flex',
                  height: 'inherit',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              filteredData?.map((data, idx) => expertItem(data, idx))
            )}
          </div>
          <div className="assign-to-expert-footer-button-area">
            {isAdmin ? (
              <Button size="large" onClick={() => setVisible(false)}>
                Later
              </Button>
            ) : null}
            {!isAdmin ? (
              <Button
                size="large"
                onClick={() => {
                  onClickNeedHelp(true);
                  setVisible(false);
                }}
              >
                Need Help
              </Button>
            ) : null}
            <Button
              size="large"
              type="primary"
              onClick={() => onSubmit()}
              disabled={!selectedExpertDetail}
            >
              {isAdmin ? 'Assign to' : 'Recommend'} {selectedExpertDetail?.first_name}{' '}
              {selectedExpertDetail?.last_name}
            </Button>
          </div>
        </div>
      </Modal>
      {/* Recommendation Model */}
      <Modal
        closable={true}
        centered
        footer={null}
        open={showRecommendationModal}
        width="600px"
        destroyOnClose={true}
        onCancel={() => setShowRecommendationModal(false)}
        className={`ai-generated-report-modal`}
      >
        <div className="assign-to-expert-modal-container">
          <div className="assign-to-expert-modal-heading">AI-generated Proposal</div>

          <Spin spinning={variant !== 'admin' && isRecommendationTextLoading}>
            <div style={{ minHeight: '240px' }}>
              {/* Edit mode */}
              {isEditMode ? (
                <TextArea
                  disabled={isRecommendationTextLoading}
                  value={isRecommendationTextLoading ? 'Loading...' : recommendationTextValue}
                  onChange={e => setRecommendationTextValue(e.target.value)}
                  autoSize={{ minRows: 16 }}
                />
              ) : (
                // Preview mode
                <div dangerouslySetInnerHTML={createMarkup(recommendationTextValue)} />
              )}
            </div>
          </Spin>

          <div className="assign-to-expert-footer-button-area">
            {isAdmin ? (
              <Button size="large" onClick={() => setShowRecommendationModal(false)}>
                Later
              </Button>
            ) : null}

            {!isAdmin ? (
              <Button
                size="large"
                onClick={() => {
                  onClickNeedHelp(true);
                  setVisible(false);
                  setShowRecommendationModal(false);
                }}
              >
                Need Help
              </Button>
            ) : null}

            {/* Toggle button for Preview/Edit */}
            {isAdmin ? (
              <Button
                disabled={isRecommendationTextLoading}
                size="large"
                onClick={() => setIsEditMode(!isEditMode)}
              >
                {isEditMode ? 'Preview' : 'Edit'}
              </Button>
            ) : null}

            <Button
              size="large"
              type="primary"
              onClick={() => onSubmit(recommendationTextValue)}
              disabled={!selectedExpertDetail || isRecommendationTextLoading}
            >
              {variant === 'admin' ? 'Assign to' : 'Recommend '}
              {selectedExpertDetail?.first_name} {selectedExpertDetail?.last_name}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        closable={isAdmin ? false : true}
        centered
        footer={null}
        open={showSameExpertModal}
        destroyOnClose={true}
        onCancel={() => {
          setShowSameExpertModal(false);
        }}
      >
        <div className="assign-to-expert-modal-container">
          <p>
            This financial professional has already been recommended to you. Please select one of
            the other professionals from the list below, which has been ranked to prioritize the
            best match for your request.
          </p>
        </div>
      </Modal>
    </>
  );
};
