import React from 'react';
import { Helmet as HelmetCompnent } from 'react-helmet';

const Helmet = ({ title = '', desc }) => {
  return (
    <>
      <HelmetCompnent>
        <title>{`Accountizer${title ? ' - ' + title : ''}`}</title>
        <meta name="description" content={`${desc || 'Accountizer'}`} />
      </HelmetCompnent>
    </>
  );
};
export default Helmet;
