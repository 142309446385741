import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import searchIcon from '../../assets/images/searchIcon.svg';
import { MessageGroupCard } from './messageGroupCard';
import { MessagingContainerForMessageGroup } from './messagingContanerForMessageGroup';
import { getUserData } from '../../helpers/functions';

export const MessagesGroupView = () => {
  const [search, setSearch] = useState({ find: '' });
  const [messageThreadLocalData, setMessageThreadLocalData] = useState([]);
  const [selectedThread, setSelectedThread] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { messageThreadData, unreadMessages, selectedTabGroup } = useSelector(
    state => state.requests,
  );
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  useEffect(() => {
    dispatch({ type: 'GET_MESSAGE_THREAD' });
    dispatch({ type: 'GET_ALL_UNREAD_MESSAGES' });
  }, []);

  useEffect(() => {
    if (messageThreadData.length) {
      setMessageThreadLocalData(messageThreadData);
      const timer = setTimeout(() => {
        dispatch({ type: 'GET_MESSAGE_THREAD' });
        dispatch({ type: 'GET_ALL_UNREAD_MESSAGES' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [dispatch, messageThreadData]);

  useEffect(() => {
    if (search.find.length) {
      messageThreadLocalData?.map((data, idx, self) => {
        data?.participants
          ?.filter((el, id, selfNew) => el?.id !== getUserData().id)
          .filter((ele, id) => {
            if (
              ele &&
              `${ele.first_name} ${ele.last_name}`
                .toLowerCase()
                .includes(search?.find?.toLowerCase())
            ) {
              setFilteredData([data]);
            }
          });
      });
    } else {
      setFilteredData(messageThreadLocalData);
    }
  }, [search, messageThreadLocalData, filteredData, messageThreadData.length]);

  const handleSearchByName = e => {
    setSearch({ ...search, find: e.target.value });
  };

  return (
    <div
      style={{ border: '0.2px solid #d1d5db', backgroundColor: 'white' }}
      className="message-group-view-body"
    >
      <div className="message-group-left-container">
        <div className="message-group-search-container">
          <Input
            className="message-group-search"
            prefix={<img style={{ marginRight: '5px' }} src={searchIcon} alt="Search" />}
            placeholder="Search by name"
            size="large"
            onChange={e => handleSearchByName(e)}
          />
        </div>
        <div
          style={{
            height: (height * 55) / 100,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
          className="message-group-left-container-card-group"
        >
          {filteredData?.map((data, idx) => {
            return (
              <div key={idx}>
                <MessageGroupCard
                  data={data}
                  unreadMessages={unreadMessages?.find(el => {
                    if (el?.thread[0]?.thread_id === data?.messages[0]?.thread_id) {
                      return el;
                    }
                    return null;
                  })}
                  selectedThread={selectedThread}
                  setSelectedThread={setSelectedThread}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ backgroundColor: 'white' }} className="message-group-right-container">
        <MessagingContainerForMessageGroup
          subjectId={parseInt(selectedThread?.Subject)}
          selectedTabGroup={selectedTabGroup}
          selectedThreadData={messageThreadData?.filter(data => data?.id === selectedTabGroup)}
        />
      </div>
    </div>
  );
};
