import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Popover, Avatar } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import MainLogo from '../../assets/images/black-logo.svg';
import WhiteLogo from '../../assets/images/white-logo.svg';
import { getFullName, getUserData, isLogin, logout, showAvatarName } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
const { Header } = Layout;

const items = [
  {
    label: 'My Requests',
    key: 'dashboard/expert/request',
  },
  {
    label: 'History',
    key: 'dashboard/expert/history',
  },
  {
    label: 'Messages',
    key: 'dashboard/expert/messages',
  },
];

const ExpertDashboardHeader = ({ role = '' }) => {
  const dispatch = useDispatch();
  const { expertDetailById, isExpertDetailLoading } = useSelector(state => state.requests);
  // hide header when print mode is on
  const { print } = useParams();

  useEffect(() => {
    if (isLogin()) {
      dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: getUserData().id });
    }
  }, []);

  useEffect(() => {
    if (expertDetailById) {
      localStorage.setItem('expertAvatar', expertDetailById?.avatar);
    }
  }, [isExpertDetailLoading]);

  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState('/expert/request');

  const onClick = e => {
    if (
      e.key === 'dashboard/expert/request' ||
      e.key === 'dashboard/expert/history' ||
      e.key === 'dashboard/expert/messages'
    ) {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    } else {
      setCurrent('');
    }
  };

  const onLogoutClick = () => {
    logout();
    navigate('/login?type=expert');
  };

  const onProfileClick = () => {
    navigate('/dashboard/expert/detail');
  };
  const content = (
    <div className="status-popover-inner-container" style={{ userSelect: 'none' }}>
      <p>{getFullName()}</p>
      <p>
        <div
          style={{ cursor: 'pointer' }}
          className="status-popover-cancel-button"
          onClick={() => onProfileClick()}
        >
          Profile
        </div>
      </p>
      <p>
        <div
          style={{ cursor: 'pointer' }}
          className="status-popover-cancel-button"
          onClick={() => onLogoutClick()}
        >
          Logout
        </div>
      </p>
    </div>
  );

  return (
    <>
      {!!print || (
        <Header className={`header ${location.pathname === '/expert' ? 'transfer' : ''}`}>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }} gutter={30}>
            <div className="main-logo">
              {location.pathname === '/expert' ? (
                <img
                  src={WhiteLogo}
                  onClick={() => navigate('/expert')}
                  className="pointer"
                  alt="Expert Logo "
                ></img>
              ) : (
                <img
                  src={MainLogo}
                  onClick={() => navigate('/expert')}
                  className="pointer"
                  alt="Expert Logo "
                ></img>
              )}
            </div>
            <div className="header-menu public-header d-flex-a-c">
              <div style={{ gap: '15px' }} className="d-flex-a-c">
                {items.map((item, index) => {
                  return (
                    <p key={index} className="mb0" onClick={e => onClick(item)}>
                      <span
                        style={{ color: current === item.key && '#1C64F2' }}
                        className="cursor-p"
                      >
                        {item.label}
                      </span>
                    </p>
                  );
                })}
              </div>
            </div>
            <div style={{ display: 'flex', gap: 10 }}>
              {isLogin() && (
                <div className="f-flex-a-e">
                  <div className="header-menu d-flex-a-center">
                    <Popover placement="bottom" content={content} trigger="hover">
                      {expertDetailById?.avatar ? (
                        <img
                          style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                          src={expertDetailById?.avatar}
                          alt=""
                        />
                      ) : (
                        <Avatar size="large" className="pointer">
                          {showAvatarName()}
                        </Avatar>
                      )}
                    </Popover>
                  </div>
                </div>
              )}
              {!isLogin() && (
                <div className="d-flex-a-c">
                  <p className="mb0 mr15">Client Login</p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="float-r"
                    size="middle"
                    onClick={() => navigate('/login?type=expert')}
                  >
                    Become an expert
                  </Button>
                </div>
              )}
            </div>
          </Row>
        </Header>
      )}
    </>
  );
};

export default ExpertDashboardHeader;
