import React, { useState, useRef } from 'react';
import { Card, Form, Button, Spin, Input, Row, Col, DatePicker, Divider, message } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../helpers/functions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { AddAnotherCard } from '../../common/addAnotherCard';

dayjs.extend(customParseFormat);
const Certification = ({ handleNext, dependent = '', handleBack }) => {
  const dispatch = useDispatch();
  const { eid } = useParams();
  const [form] = Form.useForm();
  const { isCertificateUpdating, certiMsg, certiErrorMsg } = useSelector(state => state.steps);
  const { isCertificateLoading, certificatList, isEmpty } = useSelector(state => state.expert);
  const [certificateHtml, setCertificateHtml] = useState('');
  const [tempCertificate, setTempCertificate] = useState([{}]);
  const { isAdminDeleteLicensesAndCertificateLoading } = useSelector(state => state.admin);
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef?.current?.focus({
      cursor: 'start',
    });
  }, [certificateHtml]);

  // Must add isCertificateUpdating otherwise page renders with stale data
  useEffect(() => {
    const getExpertCertification = () => {
      dispatch({
        type: 'EXPERT_CERTIFICATION_REQUEST',
        formData: { expert_id: eid || getUserData().id },
      });
    };

    getExpertCertification();
  }, [dispatch, eid, isAdminDeleteLicensesAndCertificateLoading, isCertificateUpdating]);

  useEffect(() => {
    if (!isCertificateLoading && isEmpty !== undefined) {
      let certificates;
      if (certificatList.length === 0) {
        certificates = [
          {
            expert_id: eid || getUserData().id,
            designation: '',
            issuance_date: null,
            expiration_date: null,
            license_no: '',
            designation_url: '',
          },
        ];
      } else if (certificatList.length > 0) {
        certificates = certificatList.map(item => ({
          ...item,
          issuance_date: item?.issuance_date ? dayjs(item.issuance_date, 'YYYY-MM-DD') : null,
          expiration_date: item?.expiration_date ? dayjs(item.expiration_date, 'YYYY-MM-DD') : null,
        }));
      }
      setTempCertificate({ certificates });
    }
  }, [
    isCertificateLoading,
    certificatList,
    isEmpty,
    isAdminDeleteLicensesAndCertificateLoading,
    eid,
  ]);

  useEffect(() => {
    const onFinish = values => {
      let tmpcertificate = [];
      if (values?.certificates && values.certificates.length > 0) {
        values.certificates.forEach(item => {
          let tmpObj = {};

          tmpObj.issuance_date = dayjs(new Date(item.issuance_date)).format('YYYY-MM-DD');
          tmpObj.expiration_date = item.expiration_date
            ? dayjs(new Date(item.expiration_date)).format('YYYY-MM-DD')
            : '';

          tmpObj.expert_id = eid || getUserData().id;

          if (item.designation) {
            tmpObj.designation = item.designation;
          }

          if (item.designation_url) {
            tmpObj.designation_url = item.designation_url;
          }

          if (item.license_no) {
            tmpObj.license_no = item.license_no;
          }

          if (item) {
            tmpObj.id = item.id;
          }

          tmpcertificate.push(tmpObj);
        });
      }

      if (tmpcertificate.length > 0) {
        dispatch({
          type: 'EXPERT_CERTIFICATE_UPDATE_REQUEST',
          formData: tmpcertificate,
        });
      } else {
        message.error('Please add at least one certificate');
      }
    };

    const handleCertificateDelete = (field, id, clientRemove) => {
      // handle removal of not yet saved degrees and do client side delete
      // field.name is the index of the degree in the form list
      clientRemove(field.name);
      if (id) {
        // if it was saved, it should have an id, dispatch delete action
        dispatch({ type: 'ADMIN_DELETE_LICENSE_AND_CERTIFICATE', id });
      }
    };

    const loadCertificates = () => {
      if (!tempCertificate?.certificates?.length) {
        setCertificateHtml(null);
        return;
      }

      setCertificateHtml(
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          initialValues={tempCertificate}
        >
          <Form.List name="certificates">
            {(fields, { add, remove }) => (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {fields.map(field => (
                  <div className="certificate-section" key={field.name}>
                    <Row gutter={30} className={'mb20'}>
                      {fields.length > 1 && (
                        <div className="remove-section-icon-wrapper">
                          <CloseOutlined
                            onClick={() =>
                              handleCertificateDelete(
                                field,
                                form.getFieldValue(['certificates', field.name, 'id']),
                                remove,
                              )
                            }
                            className="cursor-p"
                          />
                        </div>
                      )}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          name={[field.name, 'designation']}
                          className="form-field"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter designation(s) / qualification(s)',
                            },
                          ]}
                        >
                          <Input
                            // ref={i === tempCertificate.length - 1 ? inputRef : undefined}
                            size="large"
                            placeholder="Designation(s) / Qualification(s)"
                          />
                        </Form.Item>
                      </Col>
                      <Form.Item style={{ display: 'none' }} name={[field.name, 'id']}>
                        <Input />
                      </Form.Item>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Issuance Date"
                          name={[field.name, 'issuance_date']}
                          rules={[
                            {
                              type: 'object',
                              required: true,
                              message: 'Please select date!',
                            },
                          ]}
                        >
                          <DatePicker style={{ width: '100%' }} size="large" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Expiration date" name={[field.name, 'expiration_date']}>
                          <DatePicker style={{ width: '100%' }} size="large" />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="License No" name={[field.name, 'license_no']}>
                          <Input size="large" placeholder="License No" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label="Designation/Qualification URL"
                          name={[field.name, 'designation_url']}
                          rules={[
                            {
                              required: false,
                              message: 'Please enter URL',
                            },
                            {
                              type: 'url',
                              message: 'This field must be a valid url.',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="https://www.linkedin.com/in/" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
                <AddAnotherCard handleClick={add} text={'add another certificate'} />
              </Col>
            )}
          </Form.List>
          <Divider className="mt40 mb40" />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              className="mt5"
              disabled={isCertificateUpdating}
              loading={isCertificateUpdating}
            >
              Next
            </Button>
          </Form.Item>
        </Form>,
      );
    };

    loadCertificates();
  }, [dispatch, eid, form, isCertificateUpdating, tempCertificate]);

  useEffect(() => {
    if (!isCertificateUpdating && certiMsg === 'success' && certiErrorMsg === '') {
      handleNext('location');
      dispatch({ type: 'CLEAR_STEPS' });
    } else if (!isCertificateUpdating && certiErrorMsg !== '') {
      dispatch({ type: 'CLEAR_STEPS' });
      message.error(certiErrorMsg || 'Something went wrong');
    }
  }, [isCertificateUpdating, certiMsg, certiErrorMsg, handleNext, dispatch]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isCertificateLoading && <Spin />}
          {!isCertificateLoading && (
            <>
              <p className="m-title tl mb-7 flex-y">
                <p className="d-flex">
                  <span className="back-icon" onClick={() => handleBack('certification')}>
                    <IoMdArrowRoundBack />
                  </span>
                  <span>I hold the following professional license(s) and certificates</span>
                </p>
                <p className="help-text d-flex">
                  Insert highest professional license(s) and certificates <b>&nbsp;first</b>, as
                  this will appear on your public profile
                </p>
              </p>

              {certificateHtml}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default Certification;
