/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal } from 'antd';
import { createMarkup } from '../../../helpers/functions';
import Cookies from 'js-cookie';

const EvaluationReportModal = props => {
  const { visible, setVisible, questionDetail, handleWhatsNext } = props;

  const onCancel = () => {
    localStorage.setItem('showEvaluationReport', false);
    setVisible(false);
    const dontShowWhatsNext = Cookies.get(`dontShowWhatsNext_${questionDetail?.user?.id}`);
    if (!dontShowWhatsNext) {
      handleWhatsNext(true);
    }
  };

  return (
    <Modal
      className={`evaluation-report-modal custom-modal`}
      open={visible}
      footer={false}
      centered
      width={'100%'}
      style={{ maxWidth: '900px' }}
      maskClosable={false}
      closeIcon={true}
      closable={true}
      onCancel={() => onCancel()}
    >
      <div className="mt5 mb5 pr5 pl5">
        <h3>Dear {questionDetail?.user?.first_name + ' ' + questionDetail?.user?.last_name},</h3>
        <center>
          <span
            style={{ textAlign: 'start' }}
            dangerouslySetInnerHTML={createMarkup(questionDetail?.proposal ?? '')}
          />
        </center>
        <p>Best Regards,</p>
        <p>The Accountizer Team</p>
      </div>
    </Modal>
  );
};

export default EvaluationReportModal;
