import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  dynamicMessagingCard,
  formatDateTo,
  getUserData,
  role,
  showCustomAvatarName,
} from '../../helpers/functions';
import { ROLE_NAME } from '../../helpers/constant';

const LeftMessage = ({ m, selectedThreadData, type }) => {
  const { body, created_at, attachment_type } = m;
  const [expertAvatar, setExpertAvatar] = useState();
  const [customerDetail, setCustomerDetail] = useState();

  useEffect(() => {
    if (type === 'group') {
      const expertData =
        role() === ROLE_NAME.EXPERT
          ? selectedThreadData[0]?.participants?.filter(data => data?.id === getUserData().id)
          : selectedThreadData[0]?.participants?.filter(data => data?.id !== getUserData().id);
      if (expertData?.length) {
        setExpertAvatar(role() === ROLE_NAME.CUSTOMER ? expertData[0]?.expert_meta?.avatar : null);
      }
      if (role() === ROLE_NAME.EXPERT) {
        setCustomerDetail(
          selectedThreadData[0]?.participants?.filter(data => data?.id !== getUserData().id),
        );
      }
    } else if (type === 'container') {
      setExpertAvatar(role() === ROLE_NAME.CUSTOMER && selectedThreadData[0]?.expert_meta?.avatar);
    }
  }, []);

  return (
    <div className="message-container">
      {expertAvatar ? (
        <img
          style={{ height: '40px', width: '40px', borderRadius: '50%', marginBottom: '15px' }}
          src={expertAvatar}
          alt=""
        />
      ) : type === 'container' ? (
        <Avatar style={{ marginBottom: '15px' }} size="large" className="pointer">
          {showCustomAvatarName(
            `${selectedThreadData[0]?.first_name} ${selectedThreadData[0]?.last_name}`,
          )}
        </Avatar>
      ) : (
        <Avatar style={{ marginBottom: '15px' }} size="large" className="pointer">
          {customerDetail
            ? showCustomAvatarName(
                `${customerDetail[0]?.first_name} ${customerDetail[0]?.last_name}`,
              )
            : '-'}
        </Avatar>
      )}
      <div>
        {dynamicMessagingCard(attachment_type, body)}
        <div className="message-time">
          <p>{formatDateTo(created_at, 'hh:mm A')}</p>
        </div>
      </div>
    </div>
  );
};

export default LeftMessage;
