import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicMessagingCard } from '../../helpers/functions';

export const ArchiveSection = props => {
  const { subjectId } = props;
  const { attachmentByThreadId, isAttachmentByThreadIdLoading } = useSelector(
    state => state.requests,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isNaN(subjectId)) {
      dispatch({ type: 'GET_ATTACHMENTS_BY_THREAD_ID', id: subjectId });
    }
  }, []);

  return isAttachmentByThreadIdLoading ? (
    <div className="d-flex-a-c">
      <Spin />
    </div>
  ) : attachmentByThreadId?.length ? (
    <div className="archive-inner-body">
      {attachmentByThreadId.map(data => {
        return dynamicMessagingCard(data?.attachment_type, data?.body, 'archive');
      })}
    </div>
  ) : (
    <div className="d-flex-a-c p-3">No shared document found.</div>
  );
};
