import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Button, Rate, message } from 'antd';
import Cookies from 'js-cookie';
import QuotationModal from './modal/quatationModal';
import PaymentModal from './modal/paymentModal';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { AssignQuestionToExpertModal } from './modal/assignToModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RequestRejectModal } from './modal/requestRejectModal';
import { createMarkup } from '../../helpers/functions';
import WhatsNextModal from './modal/WhatsNextModal';

export const AssignedToExpertCard = ({ data, setSelectedTab }) => {
  const dispatch = useDispatch();
  const [quotationModal, setQuotationModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [requestRejectModal, setRequestRejectModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showFullProposal, setShowFullProposal] = useState(false);
  const [showWhatsNextModal, setShowWhatsNextModal] = useState(false);
  const [dontShowWhatsNext, setDontShowWhatsNext] = useState(false);
  const navigate = useNavigate();
  const proposalLimit = 500;

  useEffect(() => {
    const dontShowWhatsNext = Cookies.get(`dontShowWhatsNext_${data?.user?.id}`);
    if (!dontShowWhatsNext) {
      setDontShowWhatsNext(true);
    } else {
      setDontShowWhatsNext(false);
    }
  }, [showAssignModal]);

  const handleExpertRequestAccept = () => {
    dispatch({
      type: 'POST_CLIENT_ACCEPT_OR_REJECT_EXPERT_REQUEST',
      formData: { question_id: data?.id, is_approve: 1 },
    });
  };

  const handleCloseModal = () => {
    if (dontShowWhatsNext) {
      setShowWhatsNextModal(true);
    }
  };

  return (
    <div className="request-assigned-to-container">
      {data?.status?.question_status_id === 2 && data?.assign_by_admin === 0 ? (
        <h3>Find another expert</h3>
      ) : (
        <h3>We recommend</h3>
      )}

      <div className="assigned-to-profile-container">
        {data?.status?.question_status_id === 2 && data?.assign_by_admin === 0 ? (
          <div style={{ maxWidth: '70%' }}>
            Unfortunately, the expert you previously selected has rejected your request. Please
            select a different expert to assist you.
          </div>
        ) : (
          <div
            className="assigned-to-profile-left-container"
            onClick={() => navigate(`/find-expert-detail/${data?.expert?.id}`)}
          >
            <div className="assigned-to-profile-icon">
              <img
                alt="expert"
                height={60}
                width={60}
                src={data?.expert_meta?.avatar ? data?.expert_meta?.avatar : expertIcon}
              />
            </div>
            <div className="assigned-to-profile-detail">
              <div className="assigned-to-profile-name">
                {data?.expert?.first_name} {data?.expert?.last_name}
              </div>
              <div className="assigned-to-profile-role">{data?.category?.name}</div>
            </div>
          </div>
        )}

        {data?.question_review?.length &&
        (data?.status?.question_status_id === 10 || data?.status?.question_status_id === 11) ? (
          <div className="assigned-to-rating-container">
            <div className="assigned-to-rating-container-div">My Review</div>
            <Rate disabled value={data?.question_review[0]?.rating} />
          </div>
        ) : null}

        <div className="assigned-to-profile-button-group">
          {setSelectedTab && (
            <Button
              className="assigned-to-profile-button"
              type="primary"
              onClick={() => setSelectedTab('messaging')}
            >
              Messaging
            </Button>
          )}
          {data?.status?.question_status_id !== 10 &&
          data?.status?.question_status_id !== 11 &&
          data?.status?.question_status_id < 4 &&
          data?.assign_by_admin === 0 ? (
            <Button
              className="assigned-to-profile-button"
              type="primary"
              onClick={() => setShowAssignModal(true)}
            >
              Find Another
            </Button>
          ) : null}
          {data?.status?.question_status_id !== 10 &&
          data?.status?.question_status_id !== 11 &&
          // data?.status?.question_status_id < 4 &&
          data?.assign_by_admin === 1 &&
          data?.expert_approved !== 1 ? (
            <>
              <Button
                className="assigned-to-profile-button"
                type={data?.expert_approved === 1 ? 'default' : 'primary'}
                disabled={data?.expert_approved === 1}
                onClick={() => {
                  if (data?.expert_approved === 0) {
                    handleExpertRequestAccept();
                  } else {
                    message.error('Waiting for expert approval.');
                  }
                }}
              >
                Accept
              </Button>
              <Button
                className="assigned-to-profile-button"
                danger
                disabled={data?.expert_approved === 1}
                onClick={() => {
                  if (data?.expert_approved === 0) {
                    setShowAssignModal(true);
                  } else {
                    message.error('Waiting for expert approval.');
                  }
                }}
              >
                Other Options
              </Button>
            </>
          ) : null}
          {data?.status?.question_status_id === 5 && (
            <Button
              className="assigned-to-profile-button"
              type="primary"
              onClick={() => setQuotationModal(true)}
            >
              Quotation Details
            </Button>
          )}
        </div>
      </div>

      {data?.proposal ? (
        <div style={{ 'max-height': '100%' }}>
          <pre className="assigned-to-profile-proposal mt-4">
            {data.proposal.length > proposalLimit && !showFullProposal ? (
              <span
                className="request-span"
                dangerouslySetInnerHTML={createMarkup(
                  data.proposal.slice(0, proposalLimit) + '...',
                )}
              />
            ) : (
              <span
                className="request-span"
                dangerouslySetInnerHTML={createMarkup(data.proposal)}
              />
            )}
          </pre>
          {data.proposal.length > proposalLimit ? (
            <Button onClick={() => setShowFullProposal(val => !val)}>
              {showFullProposal ? 'Show less' : 'Show more'}
            </Button>
          ) : null}
        </div>
      ) : null}

      {quotationModal && (
        <QuotationModal
          type="customer"
          open={quotationModal}
          close={() => setQuotationModal(false)}
          qid={data.id}
          questionData={data}
          openPayment={() => {
            setQuotationModal(false);
            setPaymentModal(true);
          }}
        />
      )}
      {paymentModal && (
        <PaymentModal
          type="customer"
          open={paymentModal}
          close={() => setPaymentModal(false)}
          quotationId={data.quotation_detail !== null ? data?.quotation_detail?.id : ''}
          quotationDetail={data}
        />
      )}

      <AssignQuestionToExpertModal
        visible={showAssignModal}
        setVisible={setShowAssignModal}
        rowData={data}
        variant="client"
        onClose={handleCloseModal}
        onClickNeedHelp={setRequestRejectModal}
      />

      <WhatsNextModal
        visible={showWhatsNextModal}
        setVisible={setShowWhatsNextModal}
        userId={data?.user?.id}
        expertName={`${data?.expert?.first_name} ${data?.expert?.last_name}`}
      />
      <RequestRejectModal
        visible={requestRejectModal}
        setShowModal={setRequestRejectModal}
        rawData={data}
        variant="client"
      />
    </div>
  );
};
