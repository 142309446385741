/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, message, Button } from 'antd';
import React from 'react';
import CloseIcon from '../../../assets/images/red-x-icon.svg';
import { getUData } from '../../../helpers/functions';
import { useNavigate } from 'react-router-dom';

const QuestionAutomationRejectModal = props => {
  const navigate = useNavigate();
  const { visible, setVisible, navigateTo = '' } = props;
  const onCancel = () => {
    setVisible(false);
    if (navigateTo !== '') {
      navigate(navigateTo);
    }
  };
  return (
    <Modal
      className={`custom-modal`}
      open={visible}
      footer={false}
      centered
      width={450}
      maskClosable={false}
      closeIcon={false}
      closable={false}
    >
      <div className="mt5 mb5 pr5 pl5">
        <center>
          <img src={CloseIcon} alt="success icon" className="mt15 mb25"></img>
          <p className="title">Thank you for using Accountizer</p>
          <p className="sub-title">
            We couldn't automatically find a registered expert to fulfil your request, our admins
            will manually search for the best fit
          </p>
          <Button
            style={{ width: '150px', borderRadius: '30px' }}
            size="large"
            type="primary"
            onClick={() => onCancel()}
          >
            Close
          </Button>
        </center>
      </div>
    </Modal>
  );
};

export default QuestionAutomationRejectModal;
