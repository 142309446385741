import React, { useEffect } from 'react';
import { Card, Form, Button, Input, Divider } from 'antd';
import Helmet from '../common/helmet';
import Password from '../common/password';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../helpers/functions';

const ChangePassword = props => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isChaPassLoading, changePassMsg } = useSelector(state => state.auth);

  const navigate = useNavigate();
  const onFinish = values => {
    dispatch({
      type: 'CHANGE_PASSWORD_REQUEST',
      formData: {
        ...values,
        email: getUserData().email,
      },
    });
  };
  const onFinishFailed = errorInfo => {};

  useEffect(() => {
    if (!isChaPassLoading && changePassMsg === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      navigate('/profile');
    }
  }, [isChaPassLoading, changePassMsg]);

  return (
    <div className="profile d-flex-a-c">
      <Helmet title="Change Password" />
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          <p className="m-title f20 tl">Change Password</p>
          <Form
            layout="vertical"
            name="basic"
            hideRequiredMark={true}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Current password"
              name="old_password"
              rules={[
                {
                  required: true,
                  message: 'Please enter current password',
                },
              ]}
            >
              <Input.Password size="large" placeholder="••••••••••" />
            </Form.Item>

            <Password isExtra={true} />
            <Form.Item
              label="Confirm Password"
              name="password_confirmation"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('The two passwords that you entered do not match!'),
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" placeholder="••••••••••" />
            </Form.Item>
            <Divider className="mt40 mb40" />
            <Form.Item>
              <Button type="link" size={'large'} onClick={() => navigate('/profile')}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="float-r"
                size="large"
                disabled={isChaPassLoading}
                loading={isChaPassLoading}
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ChangePassword;
