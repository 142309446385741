import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Button, Form, Row, Col, Spin, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import TaxInvoice from './taxInvoice';

const StripeForm = props => {
  const { stripe, elements, onCancel, quotationId, quotationDetail, applyCouple } = props;

  const dispatch = useDispatch();
  const { isPayLoading, paymentMsg, paymentError, isTaxLoading, paymentTax } = useSelector(
    state => state.expert,
  );
  const { isCouponValidatingLoading, validCouponResponse, isCouponValid } = useSelector(
    state => state.common,
  );

  const [cardErrorMessage, setcardErrorMessage] = useState('');
  const [expirationErrorMessage, setexpirationErrorMessage] = useState('');
  const [cvvErrorMessage, setcvvErrorMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');

  const submit_stripe = async () => {
    // const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement));
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    setcardErrorMessage('');
    setexpirationErrorMessage('');
    setcvvErrorMessage('');

    if (typeof error !== 'undefined') {
      if (['incomplete_number', 'invalid_number', 'card_declined'].includes(error.code)) {
        setcardErrorMessage(error.message);
      }
      if (
        ['incomplete_expiry', 'invalid_expiry_year_past', 'invalid_expiry_month_past'].includes(
          error.code,
        )
      ) {
        setexpirationErrorMessage(error.message);
      }
      if (['incomplete_cvc'].includes(error.code)) {
        setcvvErrorMessage(error.message);
      }
    } else {
      let tmpObj = {
        quotation_id: quotationId,
        action: 'confirm',
        payment_token: paymentMethod.id,
      };
      if (couponCode !== '') {
        tmpObj.coupon_id = couponCode;
      }
      dispatch({ type: 'PAYMENT_REQUEST', formData: tmpObj });
    }
  };

  useEffect(() => {
    if (!isCouponValidatingLoading && validCouponResponse === 'success') {
      if (couponCode !== '') {
        applyCouple(couponCode);
      }
      dispatch({ type: 'CLEAR_COMMON' });
    }
  }, [isCouponValidatingLoading, validCouponResponse]);
  useEffect(() => {
    if (!isPayLoading && paymentMsg === 'success') {
      dispatch({ type: 'CLEAR_COMMON' });
      onCancel();
    }
  }, [isPayLoading, paymentMsg]);

  const onInputChange = () => {
    this.setState({
      cardErrorMessage: '',
      expirationErrorMessage: '',
      cvvErrorMessage: '',
    });
  };

  const handleOnCouponChange = code => {
    setCouponCode(code);
  };

  const validateCouponCode = () => {
    dispatch({ type: 'GET_VALIDATE_COUPON_RESPONSE', coupon: couponCode });
  };

  return (
    <div className="payment-section">
      <Form layout="vertical" name="basic">
        <Row className="mb15 mt5">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {isTaxLoading && (
              <div style={{ height: '100px' }} className="d-flex-a-c">
                <Spin />
              </div>
            )}
            {!isTaxLoading && paymentTax.tax !== undefined && paymentTax.tax !== null && (
              <TaxInvoice
                quotationDetail={quotationDetail}
                tax={paymentTax.tax}
                quotation={paymentTax?.quotation}
              />
            )}
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              validateStatus={`${cardErrorMessage !== '' ? 'error' : 'success'}`}
              help={`${cardErrorMessage !== '' ? 'Please enter valid card number' : ''}`}
              label="Credit Card Number"
            >
              <CardNumberElement
                className="form-control"
                options={{
                  placeholder: '4111 1111 1111 1111',
                }}
                onChange={() => onInputChange()}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              validateStatus={`${expirationErrorMessage !== '' ? 'error' : 'success'}`}
              help={`${expirationErrorMessage !== '' ? 'Please enter valid expiry date' : ''}`}
              label="Expiry Date"
            >
              <CardExpiryElement
                options={{
                  placeholder: 'MM / YY',
                }}
                onChange={() => onInputChange()}
                className="form-control"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="CVV"
              validateStatus={`${cvvErrorMessage !== '' ? 'error' : 'success'}`}
              help={`${cvvErrorMessage !== '' ? 'Please enter valid CVV' : ''}`}
            >
              <CardCvcElement
                className="form-control"
                options={{
                  placeholder: 'CVV',
                }}
                onChange={() => this.onInputChange()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          className="pay-btn mt15"
        >
          <Col xs={18} sm={18} md={18} lg={18} xl={18}>
            <Form.Item
              label="Coupon Code"
              help={`${cvvErrorMessage !== '' ? 'Please enter valid Coupon' : ''}`}
            >
              <Input
                defaultValue={couponCode}
                onChange={e => handleOnCouponChange(e.target.value)}
                bordered={false}
                placeholder="Enter your coupon code here."
                className="form-control"
                style={{ width: '100%', border: 'none', outline: 'none', height: '20px' }}
              />
            </Form.Item>
          </Col>
          <Button
            loading={isCouponValidatingLoading}
            style={{ height: '45px', paddingInline: '35px', marginTop: '5px', borderRadius: '8px' }}
            onClick={() => validateCouponCode()}
            disabled={couponCode === ''}
            className="mr15"
          >
            Apply
          </Button>
        </Row>
        <Row className="pay-btn mt15">
          <Button size="large" onClick={() => onCancel(false)} className="mr15">
            Cancel
          </Button>
          <Button
            size="large"
            className="form-button-next"
            type="primary"
            disabled={isPayLoading}
            loading={isPayLoading}
            onClick={() => submit_stripe()}
          >
            Confirm Payment
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default StripeForm;
