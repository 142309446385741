import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Card, Tooltip } from 'antd';
import Helmet from '../../components/common/helmet';
import ReviewCard from '../../components/common/reviewCard';
import client_home_thumbnail from '../../assets/images/client_home_video_thumbnail.png';

import { Modal } from 'antd';
import TextIcon from '../../assets/images/text-icon.svg';
import howItWork from '../../assets/images/howItWork.png';
import howItWork2 from '../../assets/images/howItWork2.png';
import howItWork3 from '../../assets/images/howItWork3.png';
import howItWork4 from '../../assets/images/howItWork4.png';
import videoCameraIcon from '../../assets/images/video-camera-icon.png';
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CUSTOMER_REVIEW } from '../../helpers/constant';
import { getUserData, isLogin } from '../../helpers/functions';
import ReactPlayer from 'react-player';
import TextArea from 'antd/es/input/TextArea';
import { ExpertTermsModal } from '../common/modal/expertTermsModal';

// import audio
import audio11 from '../../assets/audio/typeWriterSound.mp3';

// import typerwriter for text animation
import Typewriter from 'typewriter-effect';
import { Capsule } from '../common';
import { useDispatch, useSelector } from 'react-redux';

// react spring web
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef,
} from '@react-spring/web';

const NewHomePage = props => {
  const dispatch = useDispatch();
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const [visible, setVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [ispaused, setIsPaused] = useState(false);
  const [question, setQuestion] = useState('');
  const [step, setStep] = useState(1);
  const [typeWriterDisplayText, setTypeWriterDisplayText] = useState('');

  const [localData, setLocalData] = useState({});

  // ------------------ use selector for redux are here -----------------
  const {
    isSCLoading,
    serviceCategoryList,
    isQuestionLoading,
    questionDetail,
    isLocationLoading,
    locationList,
  } = useSelector(state => state.common);

  const { subCatRecords, subCatGetRecordsMessage } = useSelector(state => state.steps);
  // ----------------------------------------------//
  // console.log(subCatRecords);
  const [capsulViewRecord, setCapsulViewRecord] = useState([]);
  const [nextPageIndex, setNextPageIndex] = useState(0);

  const navigate = useNavigate();

  const redirect = () => {
    if (isLogin()) {
      navigate('/request', {
        state: { question },
      });
    } else {
      navigate('/signup');
      localStorage.setItem('redirectLink', `${window.location.origin}/request`);
    }
  };

  // -------------------//
  const textRef = useRef(''); // Store the previous text

  useEffect(() => {
    const spanElement = document.getElementsByClassName('Typewriter__wrapper')[0]; // Get the span element

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          const newText = spanElement.textContent; // Get the new text content
          if (newText !== textRef.current) {
            textRef.current = newText; // Update the reference
            // Handle text change here (e.g., console.log, update state)
            // console.log('Text changed to:', newText);
            // setTimeout(() => {

            //     playSound();
            // }, 1000);
            // document.getElementById('backgroundMusic').play();
          }
        }
      });
    });

    // Configure the observer to watch for text changes
    const config = { childList: true };
    observer.observe(spanElement, config);
    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, []); // Empty dependency array to run effect only once

  const soundElement = useRef(new Audio(audio11)); // Create audio element on first rende
  const playSound = () => {
    // const soundElement = new Audio(audio11);
    soundElement.current.currentTime = 0; // Reset audio on each keystroke
    // soundElement.current.canPlay();
    // soundElement.current.muted = true;
    var promise = soundElement.current.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          // Autoplay started
        })
        .catch(error => {
          // Autoplay was prevented.
          soundElement.current.muted = true;
          soundElement.current.play();
        });
    }
    // soundElement.current.muted = false;
    soundElement.current.addEventListener('canplay', event => {
      // console.log("Video can start, but not sure it will play through.");
    });
  };

  // ------------------------------------------------------//

  useEffect(() => {
    dispatch({ type: 'GET_SERVICE_CATEGORY_REQUEST', formData: { status: 'active' } });
  }, []);

  useEffect(() => {
    if (step === 1) {
      setCapsulViewRecord(serviceCategoryList);
      setTypeWriterDisplayText(
        'Hi there! How can we assist you today? Please select one of the following categories:',
      );
    } else if (step === 2 && subCatGetRecordsMessage === 'success') {
      setCapsulViewRecord(subCatRecords?.data);
      setTypeWriterDisplayText(
        'Could you please tell us which bookkeeping software you are currently using?',
      );
      if (subCatRecords?.next_page_url !== null) {
        setNextPageIndex(subCatRecords?.current_page + 1);
      } else {
        setNextPageIndex(0);
      }
    } else if (step === 3) {
      setTypeWriterDisplayText('Could you let us know your location?');
      if (!isLocationLoading) {
        setCapsulViewRecord(locationList);
      }
    } else if (step === 4) {
      setTypeWriterDisplayText(
        'Feel free to customize the text below to match your specific situation by typing, adding, deleting, or adjusting as needed.',
      );
    }
  }, [serviceCategoryList, step, subCatRecords, locationList]);

  const handleCapsuleClickEvent = values => {
    // check is value exists in local storage or not
    if (step === 1) {
      handleUserQuestionlocalStorageRecords({
        user_id: getUserData().id,
        category_id: { id: values?.id, name: values?.name } || null,
      });
      dispatch({
        type: 'QUESTION_SUB_CATEGORY_REQUEST',
        formData: { parent_id: values?.id, page: nextPageIndex },
      });
      dispatch({ type: 'CLEAR_STEPS' });
      setStep(2);
    } else if (step === 2) {
      handleUserQuestionlocalStorageRecords({
        sub_category_id: { id: values?.id, name: values?.name } || null,
      });
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: {} });
      dispatch({ type: 'CLEAR_STEPS' });
      setStep(3);
      setNextPageIndex(0);
    } else if (step === 3) {
      handleUserQuestionlocalStorageRecords({
        location_id: { id: values?.id, name: values?.name } || null,
      });
      setStep(4);
      setNextPageIndex(0);
    }
  };

  // handle users question local storage record
  const handleUserQuestionlocalStorageRecords = values => {
    if (localStorage.getItem('user_question')) {
      let userRecords = JSON.parse(localStorage.getItem('user_question'));
      let newRec = { ...userRecords, ...values };
      localStorage.setItem('user_question', JSON.stringify(newRec));
      setLocalData(newRec);
    } else {
      localStorage.setItem('user_question', JSON.stringify(values));
    }
  };
  // handle none of them btn handler
  function handleNoneOfThemBtn() {
    if (step === 2 && subCatRecords?.data && subCatRecords?.data.length > 0) {
      dispatch({
        type: 'QUESTION_SUB_CATEGORY_REQUEST',
        formData: { parent_id: subCatRecords?.data[0]?.parent_id, page: nextPageIndex },
      });
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }

  // function to handle back navigation in question area
  function handleBackNavgiation() {
    if (step === 2 && subCatRecords?.data) {
      if (subCatRecords?.prev_page_url !== null) {
        dispatch({
          type: 'QUESTION_SUB_CATEGORY_REQUEST',
          formData: {
            parent_id: subCatRecords?.data[0]?.parent_id,
            page: subCatRecords?.current_page - 1,
          },
        });
        dispatch({ type: 'CLEAR_STEPS' });
      } else {
        setStep(1);
        setNextPageIndex(0);
      }
    } else if (step === 3) {
      dispatch({
        type: 'QUESTION_SUB_CATEGORY_REQUEST',
        formData: {
          parent_id: subCatRecords?.data[0]?.parent_id,
          page: subCatRecords?.current_page,
        },
      });
      dispatch({ type: 'CLEAR_STEPS' });
      setStep(2);
      setNextPageIndex(0);
    } else if (step === 4) {
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: {} });
      dispatch({ type: 'CLEAR_STEPS' });
      setStep(3);
      setNextPageIndex(0);
    }
  }

  // spring web -------------------//
  const [open, set] = useState(true);

  const springApi = useSpringRef();

  const { size, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { size: '20%', background: 'hotpink' },
    to: {
      size: open ? '100%' : '20%',
      background: open ? 'white' : 'hotpink',
    },
  });

  const transApi = useSpringRef();

  const transition = useTransition(open ? capsulViewRecord : [], {
    ref: transApi,
    trail: 2400 / capsulViewRecord.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springApi, transApi] : [transApi, springApi], [0, open ? 0.1 : 4.6]);

  return (
    <div className="home-page">
      <Helmet title="Home" />
      <div className="cover-img">
        <div className="text-on-img">
          <div className="section">
            <Row gutter={75} className="mt-25">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p className="img-title">
                  1st GenAI-Powered Platform for All Your Accounting Needs
                </p>
                <p className="img-subtitle">
                  Discover the most efficient way to find and manage your numbers with handpicked
                  CPAs, bookkeepers, tax and business advisors, and financial planners.
                  <br />
                  <br />
                  Start making smarter decisions today.
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '400px' }}>
                <div className="looking-box">
                  <div className="dashboard-questions_Section">
                    <Typewriter
                      options={{
                        autoStart: true,
                        loop: false,
                        cursor: '|',
                        strings: typeWriterDisplayText,
                        delay: 35,
                      }}
                    />
                    <audio id="backgroundMusic" src={audio11}></audio>
                    <div className="category_container">
                      {(step === 1 || step === 2 || step === 3) &&
                        capsulViewRecord.map((item, index) => (
                          <Capsule
                            key={index}
                            item={item}
                            handleCapsuleClickEvent={handleCapsuleClickEvent}
                          />
                        ))}
                      {step === 4 && (
                        <div
                          style={{
                            color: 'black',
                            fontSize: '20px',
                            padding: '10px',
                            lineHeight: '1.5',
                          }}
                        >
                          <strong>“</strong>I’m a retail business owner in{' '}
                          <strong>{localData?.location_id?.name || '_______________'}</strong>,
                          operating a clothing store and handling 501-1,000 invoices per month using{' '}
                          <strong>{localData?.sub_category_id?.name || '_______________'}</strong>.
                          I need help with ensuring accurate financial management and compliance.
                          What specific{' '}
                          <strong>{localData?.category_id?.name || '_______________'}</strong>{' '}
                          services can you provide to meet these needs?<strong>”</strong>
                        </div>
                      )}

                      {/* <animated.div
                        style={{ ...rest, width: size, height: size }}
                      // className={styles.container}
                      // onClick={() => set((open) => !open)}
                      >
                        {transition((style, item) => (
                          <animated.div
                            // className={styles.item}
                            style={{ ...style, background: item.css }}
                          >
                            <Capsule
                              item={item}
                              handleCapsuleClickEvent={handleCapsuleClickEvent}
                            />
                          </animated.div>
                        ))}
                      </animated.div> */}
                    </div>

                    <div
                      className="footer_buttons"
                      style={{ position: 'absolute', bottom: '5px', width: '93%' }}
                    >
                      {step !== 1 && (
                        <Button
                          type="primary"
                          htmlType="button"
                          className=""
                          size="large"
                          onClick={() => handleBackNavgiation()}
                        >
                          <ArrowLeftOutlined /> Back
                        </Button>
                      )}
                      {nextPageIndex !== 0 && (
                        <Button
                          type="primary"
                          htmlType="button"
                          className=""
                          size="large"
                          onClick={() => handleNoneOfThemBtn()}
                        >
                          None Of Them
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Row style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Col style={{ paddingInline: '37.5px' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className=""
                    size="large"
                    onClick={() => navigate('/find-expert')}
                  >
                    Find an Expert
                  </Button>
                  <p style={{ display: 'flex', marginTop: '25px', gap: '5px' }}>
                    Click "Find an Expert" if you are confident about the qualifications, skills,
                    and experience necessary for an expert to accomplish your task.
                    <br />
                    Explore our curated selection of pre-vetted professionals and start connecting
                    with them immediately to fulfill your requirements.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    className="ai-button"
                    htmlType="submit"
                    size="large"
                    onClick={() => redirect()}
                  >
                    AI Assistant
                  </Button>
                  <Button
                    className="ai-button"
                    // htmlType="submit"
                    size="large"
                    onClick={() => navigate('/')}
                  >
                    Go To Old Dasboard
                  </Button>
                  <p style={{ display: 'flex', marginTop: '25px', gap: '5px' }}>
                    Find accounting and business assistance instantly.
                    <br />
                    Type your query, click 'Get Answer', and our AI model will match you with the
                    ideal expert in seconds, offering explanations to ensure confidence in your
                    choice.
                    <br />
                    For extra assurance, you may choose to have our recruiters verify the AI’s match
                    and rationale before it’s shown to you.
                  </p>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
      </div>

      <section className="section2">
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col xs={24} sm={12} md={12} lg={12} xl={12} className="offering-left">
            <p className="h-title">We offer solutions for</p>
            <div className="box">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Startups & SMBs</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      For those startup businesses, or for small- and medium-sized businesses
                      (SMBs), who seek access to on-demand, high-quality bookkeeping, accounting,
                      tax, and business advisory services which also fall within budget.
                    </li>
                  </ul>
                </p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Those solutions could be tracking income and expenditures, ensuring statutory
                      compliance, or representing relevant and understandable financial information
                      to investors and bankers.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="box mt25">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Individuals</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Individuals who would like to smartly manage the financial aspects of their
                      lives.
                    </li>
                    <br />
                    <li>
                      Those who would like to avoid paying more than they should during tax season.
                    </li>
                    <br />
                    <li>And/or, those who wish to avoid tax penalties.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="box mt25">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Investors and non-financial decision-makers</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Investors who want advice from a professional before committing to investing
                      in a particular business.
                    </li>
                    <br />
                    <li>
                      Decision-makers who would like to have an expert opinion before they make a
                      strategic financial decision that may impact their business's financial
                      position or profitability.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="box mt25">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Financial executives and other financial professionals</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Executive and financial professionals who are dealing with a new subject
                      matter. Those executives and professionals may not have enough training or
                      knowledge to handle the new matter, leading to the need for help of other
                      specialized accounting and business advisors.
                    </li>
                  </ul>
                </p>
                <p className="sub-title">
                  <ul>
                    <ul>
                      <li>
                        If you feel you do not fit within these categories, we can still help you.
                        Contact Accountizer to learn more about our complete accounting solutions.
                      </li>
                    </ul>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setVisible(true);
                }, [100]);
              }}
            >
              <img
                style={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
                src={client_home_thumbnail}
                alt="Accountizer Homepage"
              />
            </div>
          </Col>
          <Modal
            open={visible}
            footer={null}
            onCancel={() => {
              setIsPaused(true);
              setTimeout(() => {
                setVisible(false);
              }, [100]);
            }}
            bodyStyle={{ padding: 0 }}
            centered={true}
            width={(width * 68) / 100}
            style={{ height: (height * 75.5) / 100 }}
          >
            <ReactPlayer
              className="react-vimeo-player-modal"
              url="https://vimeo.com/809516724"
              controls={true}
              stopOnUnmount={true}
              playing={!ispaused}
              width={(width * 65) / 100}
              height={(height * 75.5) / 100}
            />
          </Modal>
        </Row>
      </section>

      <section className="section3">
        <div className="benefit">
          <div className="tc">
            <p className="h-title">Snapshots of common client requests</p>
            <div
              style={{ marginBottom: '25px', cursor: 'pointer' }}
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setModal2Visible(true);
                }, [100]);
              }}
            >
              <img style={{ marginBottom: '10px' }} src={videoCameraIcon} alt="" />
              <p className="sub-title">Watch Video</p>
            </div>

            <Modal
              open={modal2Visible}
              footer={null}
              onCancel={() => {
                setIsPaused(true);
                setTimeout(() => {
                  setModal2Visible(false);
                }, [100]);
              }}
              bodyStyle={{ padding: 0 }}
              centered={true}
              width={(width * 68) / 100}
              style={{ height: (height * 75.5) / 100 }}
            >
              <ReactPlayer
                className="react-vimeo-player-modal"
                url="https://vimeo.com/813375213"
                controls={true}
                stopOnUnmount={true}
                playing={!ispaused}
                width={(width * 65) / 100}
                height={(height * 75.5) / 100}
              />
            </Modal>
            <br />
            <p className="sub-title">
              These are areas in which our clients request the most help. But no matter your need,
              we can find the right solution for you.
            </p>
            <div className="mt50 box">
              <Row gutter={[35, 35]}>
                {benefitList.map((item, idx) => {
                  return (
                    <Col key={idx} xs={12} sm={12} md={12} lg={8} xl={8}>
                      <Card key={item.id}>
                        <img src={TextIcon} alt="Accointizer Benifits"></img>
                        <p className="title mt20">{item.title}</p>
                        <p className="desc">{item.desc}</p>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </section>

      <section className="section4">
        <div className="tc">
          <p className="h-title">How it works</p>
          <div
            style={{ marginBottom: '25px' }}
            onClick={() => {
              setIsPaused(false);
              setTimeout(() => {
                setModal3Visible(true);
              }, [100]);
            }}
          >
            <img style={{ marginBottom: '10px', cursor: 'pointer' }} src={videoCameraIcon} alt="" />
            <p className="sub-title">Watch Video</p>
          </div>
          <Modal
            open={modal3Visible}
            footer={null}
            onCancel={() => {
              setIsPaused(true);
              setTimeout(() => {
                setModal3Visible(false);
              }, [100]);
            }}
            bodyStyle={{ padding: 0 }}
            centered={true}
            width={(width * 68) / 100}
            style={{ height: (height * 75.5) / 100 }}
          >
            <ReactPlayer
              className="react-vimeo-player-modal"
              url="https://vimeo.com/812932838"
              controls={true}
              stopOnUnmount={true}
              playing={!ispaused}
              width={(width * 65) / 100}
              height={(height * 75.5) / 100}
            />
          </Modal>
          <p className="sub-title heading">Crunching your numbers can be easier than you think.</p>
        </div>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img style={{ width: '100%' }} src={howItWork} alt="Accountizer Homepage" />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Describe your needs</p>
              <p className="sub-title">
                Explain your case, and answer our few simple questions that were designed to help us
                better understand your needs. Even if you are unsure about the help you need, no
                worries; you are in the right place, and we can still help.
              </p>
            </div>
          </Col>
        </Row>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          {width <= 576 && (
            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img style={{ width: '100%' }} src={howItWork2} alt="Accountizer Homepage" />
              </div>
            </Col>
          )}
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Get matched with an expert</p>
              <p className="sub-title">
                We will connect you with a carefully picked professional who is pre-vetted for
                qualification and training, and for experience in handling clients with similar
                requirement.
              </p>
              <p className="sub-title">
                Alternatively, pick your preferred professional from our pre-vetted experts' pool
                and start communicating with your chosen professional right away.
              </p>
            </div>
          </Col>
          {width >= 576 && (
            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img style={{ width: '100%' }} src={howItWork2} alt="Accountizer Homepage" />
              </div>
            </Col>
          )}
        </Row>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img style={{ width: '100%' }} src={howItWork3} alt="Accountizer Homepage" />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Pay only when you are fully satisfied</p>
              <p className="sub-title">
                After you accept the expert’s quote for the agreed-upon service(s), you will be
                charged for the service. However, the service fee will not be released to the
                experts until they successfully complete your job.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* <section className="section7">
                <div className="benefit">
                    <div className="tc">
                        <p className="h-title">Words from our satisfied customers</p>
                        <p className="s-title tc">
                            Memorial comments from clients
                        </p>
                    </div>
                    <div className="mt50 box">
                        <div style={{ display: 'flex', maxWidth: width * 80 / 100 }}>
                            {CUSTOMER_REVIEW.map((item, idx) => {
                                return <div key={idx} className="review-card-parent"><ReviewCard item={item} /></div>
                            })}
                        </div>
                    </div>
                </div>
            </section> */}

      <section className="section6">
        <Card>
          <Row gutter={75}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex-center">
              <div>
                <p className="title mb20">Ready to get started?</p>
                <p className="sub-title mb25">
                  Choose one of our two search options to access endless resources of handpicked
                  professionals and get your work done reliably, efficiently, and at your
                  convenience.
                </p>
                <Button className="" size="large" onClick={() => navigate('/find-expert')}>
                  Get Started &nbsp;
                  <ArrowRightOutlined />
                  &nbsp;
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="tr">
              <img style={{ width: '100%' }} src={howItWork4} alt="Accountizer Homepage"></img>
            </Col>
          </Row>
        </Card>
      </section>
    </div>
  );
};

export default NewHomePage;

const benefitList = [
  {
    id: 1,
    title: 'Tax',
    desc: 'Preparation and filing for online businesses',
  },
  {
    id: 2,
    title: 'Bookkeeping',
    desc: 'Accounting software implementation and training: QuickBooks, FreshBooks, Sage, Zoho, Xero, or any other accounting and bookkeeping software',
  },
  {
    id: 3,
    title: 'Accountant',
    desc: 'For work in bookkeeping, year-end reviews, or year-end and tax preparation',
  },
  {
    id: 4,
    title: 'Cost Accountant',
    desc: 'For setting up inventory cycles and for managing COGS',
  },
  {
    id: 5,
    title: 'Business advisor',
    desc: 'Financial information analysis to pinpoint workflow weaknesses that lead to losses',
  },
  {
    id: 6,
    title: 'Payroll management',
    desc: 'For employees and contractors, bi-weekly payroll, direct deposit',
  },
];
