import React from 'react';
import { Divider } from 'antd';

const ExpertDegrees = ({ degrees, isLoading }) => {
  if (!degrees?.length || isLoading) {
    return null;
  }

  return degrees?.map(data => (
    <>
      <div className="expert-detail-right-container-row-container">
        <div className="expert-detail-right-container-question">
          I hold the following university degree(s)
        </div>
        <div className="expert-detail-right-container-answer">{data?.name}</div>
      </div>
      {/*  */}
      <div className="expert-detail-right-container-row-container">
        <div className="expert-detail-right-container-question">Issuance date YYYY-MM-DD</div>
        <div className="expert-detail-right-container-answer">{data?.issuance_date}</div>
      </div>
      {/*  */}
      <div className="expert-detail-right-container-row-container">
        <div className="expert-detail-right-container-question">Institution</div>
        <div className="expert-detail-right-container-answer">{data?.institution}</div>
      </div>
      <Divider className="mt10 mb10" />
    </>
  ));
};
export default ExpertDegrees;
