import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card } from 'antd';
import Helmet from '../../components/common/helmet';
import expert_home_thumbnail from '../../assets/images/expert_home_video_thumbnail.png';
import TextIcon from '../../assets/images/text-icon.svg';
import howItWork from '../../assets/images/howItWork.png';
import howItWork2 from '../../assets/images/howItWork2.png';
import howItWork3 from '../../assets/images/howItWork3.png';
import howItWork4 from '../../assets/images/howItWork4.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import FAQs from '../public/FAQs';
import videoCameraIcon from '../../assets/images/video-camera-icon.png';
import { isLogin } from '../../helpers/functions';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import { ExpertTermsModal } from '../common/modal/expertTermsModal';

const ExpertHomePage = props => {
  const [visible, setVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [ispaused, setIsPaused] = useState(false);
  const navigate = useNavigate();
  const redirectForSigup = () => {
    if (isLogin()) {
      navigate(`/expert/profile`);
    } else {
      localStorage.setItem('redirectLink', `${window.location.origin}/expert`);
      navigate('/expert/signup');
    }
  };
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return (
    <div className="home-page">
      <Helmet title="Home" />
      <div className="cover-img">
        <div className="text-on-img">
          <div className="section">
            <Row gutter={75}>
              <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                <p className="img-title">A place to boost your Professional career.</p>
                <p className="img-subtitle">
                  Strengthen your professional reputation and online presence, survive the fierce
                  competition, attract new clients, and increase your market share.
                </p>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={() => {
                    if (isLogin()) {
                      localStorage.setItem(
                        'redirectLink',
                        `${window.location.origin}/expert/profile`,
                      );
                      navigate('/expert/profile');
                    } else {
                      localStorage.setItem(
                        'redirectLink',
                        `${window.location.origin}/expert/profile`,
                      );
                      navigate('/expert/signup');
                    }
                  }}
                >
                  Get Started
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <section className="section2">
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col
            className="d-flex-a-c mb-10"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            onClick={() => {
              setIsPaused(false);
              setTimeout(() => {
                setVisible(true);
              }, [100]);
            }}
          >
            <img
              style={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
              src={expert_home_thumbnail}
              alt="Accountizer Homepage"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <p className="h-title">Offering accounting professionals solutions for</p>
            <div className="box">
              <img src={TextIcon} alt="icon" />
              <div>
                <p className="title">Practice expansion</p>
                <p className="sub-title">
                  For CPAs, tax preparers, business advisors, or bookkeepers, who run a professional
                  practice and would like to sustain and/or expand market share, build a more
                  prominent online presence, overcome the fierce market competition, and attract new
                  clients.
                </p>
              </div>
            </div>
            <div className="box mt25">
              <img src={TextIcon} alt="icon" />
              <div>
                <p className="title">Starting a practice</p>
                <p className="sub-title">
                  For accounting professionals looking forward to starting their own practice.
                  Accountizer will help by building client networks, and by introducing you to
                  clients interested in your industry experience.
                </p>
              </div>
            </div>
          </Col>
          <Modal
            open={visible}
            width={(width * 68) / 100}
            style={{ height: (height * 75.5) / 100 }}
            footer={null}
            onCancel={() => {
              setIsPaused(true);
              setTimeout(() => {
                setVisible(false);
              }, [100]);
            }}
            bodyStyle={{ padding: 0 }}
            centered={true}
          >
            <ReactPlayer
              className="react-vimeo-player-modal"
              url="https://vimeo.com/802990546"
              controls={true}
              stopOnUnmount={true}
              playing={!ispaused}
              width={(width * 65) / 100}
              height={(height * 75.5) / 100}
            />
          </Modal>
        </Row>
      </section>

      <section className="section3">
        <div className="benefit">
          <div className="tc">
            <p className="h-title mb10">Benefits for experts</p>
            <div
              style={{ marginBottom: '25px' }}
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setModal2Visible(true);
                }, [100]);
              }}
            >
              <img style={{ marginBottom: '10px' }} src={videoCameraIcon} alt="" />
              <p className="sub-title">Watch Video</p>
            </div>
            <Modal
              open={modal2Visible}
              width={(width * 68) / 100}
              style={{ height: (height * 75.5) / 100 }}
              footer={null}
              onCancel={() => {
                setIsPaused(true);
                setTimeout(() => {
                  setModal2Visible(false);
                }, [100]);
              }}
              bodyStyle={{ padding: 0 }}
              centered={true}
            >
              <ReactPlayer
                className="react-vimeo-player-modal"
                url="https://vimeo.com/808442055"
                controls={true}
                stopOnUnmount={true}
                playing={!ispaused}
                width={(width * 65) / 100}
                height={(height * 75.5) / 100}
              />
            </Modal>
            <p className="sub-title">
              Join us today for free to take advantage of Accountizer’s major benefits to
              professionals.
            </p>
            <div className="mt50 box">
              <Row className="d-flex-a-c" gutter={[35, 35]}>
                {benefitList.map(item => {
                  return (
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                      <Card key={item.id}>
                        <img src={TextIcon} alt="Accointizer Benifits"></img>
                        <p className="title mt20">{item.title}</p>
                        <p className="desc">{item.desc}</p>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </section>

      <section className="section4">
        <div className="tc">
          <p className="h-title mb10">How it works</p>
          <div
            style={{ marginBottom: '25px' }}
            onClick={() => {
              setIsPaused(false);
              setTimeout(() => {
                setModal3Visible(true);
              }, [100]);
            }}
          >
            <img style={{ marginBottom: '10px' }} src={videoCameraIcon} alt="" />
            <p className="sub-title">Watch Video</p>
          </div>
          <p className="sub-title">
            Sign up to connect with clients who need your precise skill set and knowledge
          </p>
        </div>
        <Modal
          open={modal3Visible}
          footer={null}
          width={(width * 68) / 100}
          onCancel={() => {
            setIsPaused(true);
            setTimeout(() => {
              setModal3Visible(false);
            }, [100]);
          }}
          bodyStyle={{ padding: 0 }}
          centered={true}
        >
          <ReactPlayer
            className="react-vimeo-player-modal"
            url="https://vimeo.com/807290856"
            controls={true}
            stopOnUnmount={true}
            playing={!ispaused}
            width={(width * 65) / 100}
            height={(height * 75.5) / 100}
          />
        </Modal>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img style={{ width: '100%' }} src={howItWork} alt="Accountizer Homepage"></img>
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Sign up for free</p>
              <p className="sub-title">
                Fill out the online application form, which gives us a clear understanding of your
                experience, skill set, and knowledge. Your account will be activated once the
                screening process is successfully completed.
              </p>
            </div>
          </Col>
        </Row>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          {width <= 576 && (
            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img style={{ width: '100%' }} src={howItWork2} alt="Accountizer Homepage" />
              </div>
            </Col>
          )}
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Get connected to the best-matched clients</p>
              <p className="sub-title">
                We will connect you with carefully picked clients who need your precise skill set
                and knowledge (you will have access to our secure portals to communicate with your
                clients)
              </p>
            </div>
          </Col>
          {width >= 576 && (
            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img style={{ width: '100%' }} src={howItWork2} alt="Accountizer Homepage" />
              </div>
            </Col>
          )}
        </Row>

        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img style={{ width: '100%' }} src={howItWork3} alt="Accountizer Homepage" />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Get paid</p>
              <p className="sub-title">
                Complete the work, get the client's consent, and mark it as a Completed Work to earn
                the service fee. Any amount you earn during a calendar month will be paid to you
                within the first ten days of the succeeding month.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      <section className="section5">
        <div className="benefit">
          <p className="m-title tc">Frequently Asked Questions</p>

          <div className="mt50 box">
            <FAQs type="expert" count={5} />
          </div>
          <center>
            <Button
              type="primary"
              htmlType="submit"
              className="mt35"
              size="large"
              onClick={() => navigate('/expert/faq')}
            >
              View all
            </Button>
          </center>
        </div>
      </section>

      <section className="section6">
        <Card>
          <Row gutter={75}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex-center">
              <div>
                <p className="title mb20">Ready to get started?</p>
                <p className="sub-title mb25">
                  Sign up for free, fill out the online application form, complete your screening
                  process, and get connected to boost your accounting career today.
                </p>
                <Button size="large" onClick={() => redirectForSigup()}>
                  Get Started &nbsp;
                  <ArrowRightOutlined />
                  &nbsp;
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="tr">
              <img style={{ width: '100%' }} src={howItWork4} alt="Accountizer Homepage"></img>
            </Col>
          </Row>
        </Card>
      </section>
    </div>
  );
};

export default ExpertHomePage;

const benefitList = [
  {
    id: 1,
    title: 'Market exposure',
    desc: 'Attract new clients, and extend your market share',
  },
  {
    id: 2,
    title: 'Online presence',
    desc: 'Save on marketing costs from social media ads,webinars, content creation, or SEO research, and increase your online presence for free.',
  },
  {
    id: 3,
    title: 'Build a positive and professional reputation',
    desc: 'Joining the Accountizer community shows that you were vetted for your qualifications, skills,and knowledge; this helps build an instantly high level of trust with new clients.',
  },
  {
    id: 4,
    title: 'Stay in the know',
    desc: 'You will join an extensive network of experienced accountants, and will receive timely updates on the latest financial industry news and trends.',
  },
  {
    id: 5,
    title: 'Professional development',
    desc: 'Accountizer enables you to track your performance and skill levels while also saving on your Continuing Professional Development (CPD) cost.',
  },
  {
    id: 6,
    title: 'Go virtual',
    desc: 'Accountizer is a fully remote platform- enjoy your freedom, work from anywhere, and save on overhead costs.',
  },
];
