/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Input, Row, Col } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';

/* Type of Services */
const Step4 = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { isSTLoading, serviceTypeList, isQuestionLoading, questionDetail } = useSelector(
    state => state.common,
  );
  const { isStepUpdating, stepUpdateMsg } = useSelector(state => state.steps);
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    dispatch({ type: 'GET_SERVICE_TYPE_REQUEST', formData: { status: 'active' } });
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
  }, []);

  useEffect(() => {
    if (!isQuestionLoading && Object.keys(questionDetail).length > 0) {
      const serviceId = questionDetail.service !== null ? questionDetail.service?.id : '';
      setSelectedType(serviceId);
      form.setFieldsValue({ service_id: serviceId });
    }
  }, [isQuestionLoading, questionDetail]);

  const onChange = e => {
    setSelectedType(e.target.value);
  };

  const onFinish = values => {
    if (qid) {
      dispatch({
        type: 'QUESTION_STEP_UPDATE_REQUEST',
        formData: {
          ...values,
          user_id: getUserData().id,
          id: parseInt(qid),
        },
        requestType: 'post',
        step: 4,
      });
    }
  };

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      if (selectedType === 1) {
        handleNext(qid, 'estimated-level');
      } else {
        handleNext(qid, 'sub-category', 'service-type');
      }
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStepUpdating, stepUpdateMsg]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isSTLoading && <Spin />}
          {!isSTLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack(qid, 'service-type')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>Type of services</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="service_id"
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Radio.Group className={'mt3'} style={{ width: '100%' }} onChange={onChange}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {serviceTypeList.map(item => {
                        return (
                          <>
                            <Radio style={{ fontSize: '18px' }} key={item.id} value={item.id}>
                              {' '}
                              {item.customer_label}
                            </Radio>
                          </>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    disabled={isStepUpdating}
                    loading={isStepUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>

              {/* <Form
                                layout="vertical"
                                name="basic"
                                hideRequiredMark={true}
                                autoComplete="off"
                            >
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="float-r"
                                        size="middle"
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form> */}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default Step4;
