import React, { useState } from 'react';
import { Layout, Button, Row, Col, Popover, Avatar } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import MainLogo from '../../assets/images/black-logo.svg';
import WhiteLogo from '../../assets/images/white-logo.svg';
import { getFullName, isLogin, logout, showAvatarName } from '../../helpers/functions';
const { Header } = Layout;

const items = [
  {
    label: 'My Requests',
    key: 'dashboard/customer',
  },
  {
    label: 'History',
    key: 'dashboard/customer/history',
  },
  {
    label: 'Messages',
    key: 'dashboard/customer/messages',
  },
];

const CustomerHeader = ({ role = '' }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState();

  const onClick = e => {
    if (
      e.key === 'dashboard/customer' ||
      e.key === 'dashboard/customer/history' ||
      e.key === 'dashboard/customer/messages'
    ) {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    } else {
      setCurrent('');
    }
  };

  const onLogoutClick = () => {
    logout();
    navigate('/login?type=customer');
  };
  const content = (
    <div style={{ userSelect: 'none' }}>
      <p>{getFullName()}</p>
      <p style={{ cursor: 'pointer' }} onClick={() => navigate('/profile')}>
        {'Profile'}
      </p>
      <p>
        <span
          style={{ cursor: 'pointer' }}
          className="header-menu pointer"
          onClick={() => onLogoutClick()}
        >
          Logout
        </span>
      </p>
    </div>
  );

  return (
    <>
      <Header className={`header ${location.pathname === '/' ? 'transfer' : ''}`}>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }} gutter={30}>
          <div className="main-logo">
            {location.pathname === '/' ? (
              <img
                src={WhiteLogo}
                onClick={() => navigate('/')}
                className="pointer"
                alt="Accountizer Logo "
              ></img>
            ) : (
              <img
                src={MainLogo}
                onClick={() => navigate('/')}
                className="pointer"
                alt="Accountizer Logo "
              ></img>
            )}
          </div>

          <div className="header-menu public-header d-flex-a-c">
            <div style={{ gap: '15px' }} className="d-flex-a-c">
              {items.map((item, index) => {
                return (
                  <p key={index} className="mb0" onClick={e => onClick(item)}>
                    <span style={{ color: current === item.key && '#1C64F2' }} className="cursor-p">
                      {item.label}
                    </span>
                  </p>
                );
              })}
            </div>
          </div>

          <div style={{ display: 'flex', gap: 10 }}>
            {isLogin() && (
              <div className="f-flex-a-e">
                <div className="header-menu d-flex-a-center">
                  <Popover placement="bottom" content={content} trigger="hover">
                    <Avatar size="large" className="pointer">
                      {showAvatarName()}
                    </Avatar>
                  </Popover>
                </div>
              </div>
            )}
            {!isLogin() && (
              <div className="d-flex-a-c">
                <p className="mb0 mr15">Client Login</p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="float-r"
                  size="middle"
                  onClick={() => navigate('/expert')}
                >
                  Become an expert
                </Button>
              </div>
            )}
          </div>
        </Row>
      </Header>
    </>
  );
};

export default CustomerHeader;
