/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, message, Button } from 'antd';
import React from 'react';
import SuccessIcon from '../../../assets/images/successicon.svg';
import { getUData } from '../../../helpers/functions';
import { useNavigate } from 'react-router-dom';

const QuestionAutomationSuccessModal = props => {
  const navigate = useNavigate();
  const { visible, setVisible, navigateTo = '' } = props;
  const onCancel = () => {
    setVisible(false);
    if (navigateTo !== '') {
      localStorage.setItem('showEvaluationReport', true);
      navigate(navigateTo);
    }
  };
  return (
    <Modal
      className={`custom-modal`}
      open={visible}
      footer={false}
      centered
      width={450}
      maskClosable={false}
      closeIcon={false}
      closable={false}
    >
      <div className="mt5 mb5 pr5 pl5">
        <center>
          <img src={SuccessIcon} alt="success icon" className="mt15 mb25"></img>
          <p className="title">Thank you for using Accountizer</p>
          <p className="sub-title">
            An expert has been successfully assigned to your request, close for more details
          </p>
          <Button
            style={{ width: '150px', borderRadius: '30px' }}
            size="large"
            type="primary"
            onClick={() => onCancel()}
          >
            Close
          </Button>
        </center>
      </div>
    </Modal>
  );
};

export default QuestionAutomationSuccessModal;
