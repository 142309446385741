import { useNavigate } from 'react-router-dom';
import logo3 from '../../assets/images/logo3.svg';
import './style.scss';

const Parent = props => {
  const navigate = useNavigate();

  const handleRedirectHome = () => {
    navigate('/');
  };

  return (
    <div className="bg-img">
      <div className="container">
        <div className="">
          <div style={{ cursor: 'pointer' }} className="w100 tc" onClick={handleRedirectHome}>
            <img src={logo3} className="mb25" alt="Accountizer Logo" />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Parent;
