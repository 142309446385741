import { Divider, FloatButton } from 'antd';
import { Form, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ImagePreview from '../imagePreview';
import { FaFilePdf } from 'react-icons/fa';
import ExpertDegrees from '../../expert/steps/expertDegrees';
import { OTHER_SUB_CATEGORY_IDS } from '../../../helpers/constant';

export const ExpertApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    expertDetailById,
    expertExperienceDetailById,
    expertLicensesDetailById,
    expertServiceAndRateDetailById,
    isExpertDetailLoading,
    expertDegreesDetailById,
    isExpertDegreesDetailLoading,
  } = useSelector(state => state.requests);
  const { locationList } = useSelector(state => state.common);
  const { isUpdating } = useSelector(state => state.auth);
  const { qid, print } = useParams();
  useEffect(() => {
    if (locationList.length === 0) {
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
    }

    dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: qid });
    dispatch({ type: 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID', formData: { expert_id: qid } });
    dispatch({ type: 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID', formData: { expert_id: qid } });
    dispatch({ type: 'GET_EXPERT_LICENSES_DETAIL_BY_ID', formData: { expert_id: qid } });
    dispatch({ type: 'GET_EXPERT_DEGREES_DETAIL_BY_ID', formData: { expert_id: qid } });

    setEditingBio(false);
  }, [dispatch, isUpdating, locationList.length, qid]);

  useEffect(() => {
    if (!isExpertDetailLoading) {
      setExpertBiography(expertDetailById?.bio);
    }

    if (!!print && !isExpertDetailLoading) {
      // Ensure page is rendered
      setTimeout(() => {
        handlePrint();
      }, 250);
    }
  }, [print, isExpertDetailLoading, expertDetailById]);

  const [isEditingBio, setEditingBio] = useState(false);
  const [expertBiography, setExpertBiography] = useState(null);

  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const handlePrint = () => {
    window.print();
  };
  const handlePrintNavigation = () => {
    window.open(`/application-detail/${qid}/true`, '_blank');
  };
  const handleEditNavigation = () => {
    navigate(`/expert/profile/${qid}`);
  };

  const onUpdateExpertBiographySubmit = () => {
    dispatch({
      type: 'UPDATE_PROFILE_REQUEST',
      formData: {
        id: qid,
        bio: expertBiography,
      },
    });
  };

  return (
    <div className="expert-detail-body">
      {isExpertDetailLoading ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <div className="expert-detail-left-container">
          {!print && (
            <FloatButton.Group shape="circle">
              <FloatButton
                tooltip="Edit Expert Detail"
                icon={<EditOutlined />}
                onClick={handleEditNavigation}
              />
              <FloatButton
                tooltip="Print PDF"
                icon={<FaFilePdf color="#a50a00" />}
                onClick={handlePrintNavigation}
              />
            </FloatButton.Group>
          )}
          <div style={{ backgroundColor: 'white' }} className="expert-detail-right-container">
            <div className="expert-detail-right-container-title">Expert Detail</div>

            <div className="d-flex expert-detail-right-wrapper">
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Profile Picture</div>
                <div className="expert-detail-right-container-answer">
                  <ImagePreview src={expertDetailById.avatar}></ImagePreview>
                </div>
              </div>
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Name</div>
                <div className="expert-detail-right-container-answer">
                  {expertDetailById.first_name + ' ' + expertDetailById.last_name}
                </div>
              </div>
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Email</div>
                <div className="expert-detail-right-container-answer">{expertDetailById.email}</div>
              </div>
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">
                  Are you authorized to work in Canada?
                </div>
                <div className="expert-detail-right-container-answer">
                  {expertDetailById?.is_authorized === 0 ? 'No' : 'Yes'}
                </div>
              </div>
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Linkedin profile</div>
                <div className="expert-detail-right-container-answer">
                  {expertDetailById?.linkedin_profile}
                </div>
              </div>
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Resume</div>
                {expertDetailById?.upload_resume ? (
                  <div className="expert-detail-right-container-answer">
                    <a
                      style={{ color: '#000', textDecoration: 'underline' }}
                      href={`${expertDetailById?.upload_resume}`}
                    >
                      View file
                    </a>
                  </div>
                ) : (
                  <div className="expert-detail-right-container-answer">-</div>
                )}
              </div>
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Biography</div>
                <div className="expert-detail-right-container-answer">
                  {isEditingBio ? (
                    <Form layout="vertical" name="basic" hideRequiredMark={true} autoComplete="off">
                      <Form.Item
                        name="bio"
                        rules={[
                          {
                            required: true,
                            message: 'Expert biography cannot be empty',
                          },
                          {
                            max: 2000,
                            message: 'Maximum 2000 characters are allowed',
                          },
                        ]}
                      >
                        <TextArea
                          defaultValue={expertDetailById?.bio}
                          rows={6}
                          size="large"
                          placeholder="Biography"
                          value={expertBiography}
                          onChange={e => {
                            setExpertBiography(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Button
                        onClick={onUpdateExpertBiographySubmit}
                        htmlType="submit"
                        size="small"
                        icon={<SaveOutlined />}
                        iconPosition="end"
                      >
                        Save
                      </Button>
                    </Form>
                  ) : (
                    <>
                      <div className="expert-bio">{expertDetailById?.bio || '-'}</div>
                      {!print && (
                        <>
                          <br />
                          <Button
                            onClick={() => setEditingBio(true)}
                            icon={<EditOutlined />}
                            iconPosition="end"
                            size="small"
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">I prefer to serve</div>
                <div className="expert-detail-right-container-answer">
                  {expertDetailById['prefer-customer']?.map((el, i) => {
                    return (
                      <span key={i}>
                        {el.name}
                        <br />
                      </span>
                    );
                  })}
                </div>
              </div>
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">
                  I am ready to help them with
                </div>
                <div className="expert-detail-right-container-answer">
                  {expertDetailById?.category.length ? (
                    expertDetailById?.category?.map((el, i) => {
                      return (
                        <span key={i}>
                          {el.name}
                          <br />
                        </span>
                      );
                    })
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <Divider className="mt10 mb10" />
              {/*  */}
              <ExpertDegrees
                degrees={expertDegreesDetailById?.data}
                isLoading={isExpertDegreesDetailLoading}
              />
              {/*  */}
              {expertServiceAndRateDetailById &&
                expertServiceAndRateDetailById?.data?.length &&
                expertLicensesDetailById?.data?.map((data, index) => {
                  return (
                    <div key={index}>
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          I hold the following professional license(s) and certificates
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {data?.designation}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          Issuance date MM/YYYY
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {data?.issuance_date}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          Expiration date (If any) MM/YYYY
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {data?.expiration_date}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">License No.</div>
                        <div className="expert-detail-right-container-answer">
                          {data?.license_no}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          Designation/qualification URL
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {data?.designation_url}
                        </div>
                      </div>
                      <Divider className="mt10 mb10" />
                    </div>
                  );
                })}
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">
                  I prefer to serve clients within
                </div>
                <div className="expert-detail-right-container-answer">
                  {expertDetailById?.locations?.map((el, i) => {
                    return (
                      <span key={i}>
                        {el?.name}
                        <br />
                      </span>
                    );
                  })}
                </div>
              </div>
              <Divider className="mt10 mb10" />
              {/*  */}
              <div className="expert-detail-right-container-row-container">
                <div className="expert-detail-right-container-question">Language I speak</div>
                <div className="expert-detail-right-container-answer">
                  {expertDetailById?.languages?.length ? (
                    expertDetailById?.languages?.map((el, i) => {
                      return (
                        <span key={i}>
                          {el?.name}
                          <br />
                        </span>
                      );
                    })
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <Divider className="mt10 mb10" />
              {expertServiceAndRateDetailById &&
                expertServiceAndRateDetailById?.data?.length &&
                expertServiceAndRateDetailById?.data &&
                expertExperienceDetailById?.data?.map((el, id) => {
                  return (
                    <div key={id}>
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          I have experience in this/these industry(ies)
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {el?.industry?.name}
                          {/* Show industry sub categories */}
                          {el?.industry_sub_categories.length > 0 && (
                            <div>
                              (
                              {el?.industry_sub_categories?.map((subEl, subI, arr) => (
                                <span key={subI}>
                                  {subEl?.name}
                                  {subI < arr.length - 1 && ', '}
                                </span>
                              ))}
                              )
                            </div>
                          )}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">No. years</div>
                        <div className="expert-detail-right-container-answer">
                          {/* {calculateTotalNumberOfExperienceInYear()} */}
                          {el?.number_of_years}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">No. months</div>
                        <div className="expert-detail-right-container-answer">
                          {/* {calculateTotalNumberOfExperienceInMonths()} */}
                          {el?.number_of_months}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          Experience gained while working
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {el?.experience_gained}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          Privately owned business
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {el.privately_owned_business === 0 ? 'No' : 'Yes'}
                        </div>
                      </div>
                      <Divider className="mt10 mb10" />
                    </div>
                  );
                })}
              {/*  */}
              {expertServiceAndRateDetailById &&
                expertServiceAndRateDetailById?.data?.length &&
                expertServiceAndRateDetailById?.data &&
                expertServiceAndRateDetailById?.data?.map((data, index) => {
                  return (
                    <div key={index}>
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          {data.main_category.name}
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {OTHER_SUB_CATEGORY_IDS.includes(data.sub_category.id)
                            ? data.other_sub_category_name
                            : data.sub_category?.name}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">ASPE</div>
                        <div className="expert-detail-right-container-answer">
                          {data.aspe === 0 ? 'No' : 'Yes'}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">IFRS</div>
                        <div className="expert-detail-right-container-answer">
                          {data.ifrs === 0 ? 'No' : 'Yes'}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">
                          Experience level
                        </div>
                        <div className="expert-detail-right-container-answer">
                          {data?.experience_level?.name}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">Rate type</div>
                        <div className="expert-detail-right-container-answer">
                          {data?.rate_type?.name}
                        </div>
                      </div>
                      {/*  */}
                      <div className="expert-detail-right-container-row-container">
                        <div className="expert-detail-right-container-question">Fees rate</div>
                        <div className="expert-detail-right-container-answer">
                          CAD${data?.rate_from} - CAD${data?.rate_to}
                        </div>
                      </div>
                      <Divider className="mt10 mb10" />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
