import { put, takeLatest, call } from 'redux-saga/effects';
//Import API Service.
import { getRequestAPI, postRequestAPI, postRequestForMessagingAPI } from '../../helpers/service';

function* getAllMyMessageThread() {
  try {
    const response = yield call(getRequestAPI, { url: `my-threads`, params: {} });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_MESSAGE_THREAD_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_MESSAGE_THREAD_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'GET_MESSAGE_THREAD_ERROR' });
  }
}

function* postMessage({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `threads/add/message/${formData.id}`,
      data: formData.message,
    });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'POST_MESSAGE_STATUS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
  }
}

function* createNewMessageThread({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `threads/add`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'POST_NEW_MESSAGE_THREAD_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'POST_NEW_MESSAGE_THREAD_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'POST_NEW_MESSAGE_THREAD_ERROR' });
  }
}

function* getThreadBySubjectId({ id }) {
  try {
    const response = yield call(postRequestForMessagingAPI, { url: `threads/${id}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_THREAD_BY_SUBJECT_ID_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_THREAD_BY_SUBJECT_ID_ERROR', error: response });
    }
  } catch (error) {
    yield put({ type: 'GET_THREAD_BY_SUBJECT_ID_ERROR', error: error.message || '' });
  }
}

function* getUnreadMessages() {
  try {
    const response = yield call(getRequestAPI, { url: `thread/unread`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_ALL_UNREAD_MESSAGES_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_ALL_UNREAD_MESSAGES_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_ALL_UNREAD_MESSAGES_ERROR', error: error.message || '' });
  }
}

function* markAllMessagesByThreadId({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `thread/mark-as-read`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'MARK_ALL_MESSAGES_AS_READ_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'MARK_ALL_MESSAGES_AS_READ_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'MARK_ALL_MESSAGES_AS_READ_ERROR', error: error.message || '' });
  }
}

function* uploadImageToDB({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: 'uploading-image', data: formData });
    if (response.status === 200) {
      if (response?.data?.success) {
        yield put({ type: 'UPLOAD_IMAGE_TO_DB_SUCCESS', data: response.data?.url || '' });
      } else {
        yield put({ type: 'UPLOAD_IMAGE_TO_DB_ERROR', error: 'No record found' });
      }
    } else {
      yield put({ type: 'UPLOAD_IMAGE_TO_DB_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'UPLOAD_IMAGE_TO_DB_ERROR', error: error.message || '' });
  }
}

function* getAttachmentsByThreadId({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `thread/attachment/${id}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_ATTACHMENTS_BY_THREAD_ID_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_ATTACHMENTS_BY_THREAD_ID_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_ATTACHMENTS_BY_THREAD_ID_ERROR', error: error.message || '' });
  }
}

function* setSelectedChatGroup({ id }) {
  yield put({ type: 'SET_SELECTED_TAB_GROUP_SUCCESS', data: id });
}

function* watchCommonSagaRequests() {
  yield takeLatest('GET_THREAD_BY_SUBJECT_ID', getThreadBySubjectId);
  yield takeLatest('GET_ALL_UNREAD_MESSAGES', getUnreadMessages);
  yield takeLatest('GET_ATTACHMENTS_BY_THREAD_ID', getAttachmentsByThreadId);
  yield takeLatest('GET_MESSAGE_THREAD', getAllMyMessageThread);
  yield takeLatest('SET_SELECTED_TAB_GROUP', setSelectedChatGroup);
  yield takeLatest('POST_NEW_MESSAGE_THREAD', createNewMessageThread);
  yield takeLatest('POST_MESSAGE', postMessage);
  yield takeLatest('MARK_ALL_MESSAGES_AS_READ', markAllMessagesByThreadId);
  yield takeLatest('UPLOAD_IMAGE_TO_DB', uploadImageToDB);
}

export function* MessagingSaga() {
  yield watchCommonSagaRequests();
}
