import React, { useEffect } from 'react';
import { Card, Form, Button, Input, Divider, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Parent from './parent';
import Helmet from '../common/helmet';
import { role } from '../../helpers/functions';
import { ROLE_NAME } from '../../helpers/constant';
import { useState } from 'react';
import VerifiedOTPModal from '../common/modal/verifiedOTPModal';
import RadioButtonImage from '../common/radioButtonImage';
import expertSignupChoice from '../../assets/images/expertSignupChoice.jpg';
import clientSignupChoice from '../../assets/images/clientSignupChoice.jpg';

const Login = props => {
  const dispatch = useDispatch();
  const { isLoginLoading, loginMsg, loginData } = useSelector(state => state.auth);

  const [showModal, setShowModal] = useState(false);

  const [loginType, setLoginType] = useState();
  const [isSignupToggle, setIsSignupToggle] = useState(false);

  const onFinish = values => {
    dispatch({ type: 'LOGIN_REQUEST', formData: values });
  };
  const onFinishFailed = errorInfo => {};

  useEffect(() => {
    if (!isLoginLoading && loginMsg === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      if (localStorage.getItem('redirectLink')) {
        let tmoURL = localStorage.getItem('redirectLink');
        localStorage.removeItem('redirectLink');
        window.location.href = tmoURL;
      } else {
        if (role() === ROLE_NAME.ADMIN) {
          window.location.href = '/admin/requests';
        } else if (role() === ROLE_NAME.EXPERT) {
          window.location.href = '/dashboard/expert/request';
        } else {
          window.location.href = '/dashboard/customer';
        }
      }
    } else if (!isLoginLoading && loginMsg === 'not-verified') {
      message.error('Please verified your email address');
      setShowModal(true);
    }
  }, [dispatch, isLoginLoading, loginMsg, loginData]);
  const SIGNUP_TYPES = [
    {
      value: 'expert',
      img: expertSignupChoice,
      label: 'Expert',
    },
    {
      value: 'customer',
      img: clientSignupChoice,
      label: 'Client',
    },
  ];

  return (
    <Parent>
      <Helmet title="Login" />
      <div className="login-card">
        <div className="site-card-border-less-wrapper">
          <Card bordered={false}>
            {isSignupToggle ? (
              // Signup form
              <article>
                <p className="m-title">Signup as</p>
                <RadioButtonImage
                  setCurrentValue={setLoginType}
                  buttonList={SIGNUP_TYPES}
                ></RadioButtonImage>
                <Link to={loginType === 'customer' ? '/signup' : '/expert/signup'}>
                  <Button
                    style={{ marginBottom: '24px' }}
                    disabled={!loginType}
                    type="primary"
                    htmlType="submit"
                    className="w100"
                    size="large"
                  >
                    Signup
                  </Button>
                </Link>
                <div className="mt8 tc">
                  <p>
                    Have an account?{' '}
                    <span
                      onClick={() => setIsSignupToggle(false)}
                      style={{ color: '#478bff', cursor: 'pointer' }}
                    >
                      Login
                    </span>
                  </p>
                </div>
              </article>
            ) : (
              // Login form
              <article>
                <p className="m-title">Login</p>
                <Form
                  layout="vertical"
                  name="basic"
                  hideRequiredMark={true}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email address"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter email address',
                      },
                      {
                        type: 'email',
                        message: 'Please enter valid email address',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Email address" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter password',
                      },
                    ]}
                  >
                    <Input.Password size="large" placeholder="••••••••••" />
                  </Form.Item>

                  <Form.Item name="remember" valuePropName="checked" className="tr">
                    <Link to="/forget-password" className="b500">
                      Forgot Password?
                    </Link>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w100"
                      size="large"
                      loading={isLoginLoading}
                      disabled={isLoginLoading}
                    >
                      Login
                    </Button>
                  </Form.Item>
                  <div className="mt8 tc">
                    <p>
                      Don't have an account yet?{' '}
                      <span
                        onClick={() => {
                          setIsSignupToggle(true);
                          setLoginType();
                        }}
                        style={{ color: '#478bff', cursor: 'pointer' }}
                      >
                        Signup
                      </span>
                    </p>
                  </div>
                </Form>
              </article>
            )}
          </Card>
        </div>
      </div>
      {showModal && (
        <VerifiedOTPModal
          visible={showModal}
          setVisible={() => {
            setShowModal(false);
          }}
        />
      )}
    </Parent>
  );
};

export default Login;
