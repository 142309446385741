import { Steps } from 'antd';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { formatDateTo } from '../../helpers/functions';
import { useNavigate } from 'react-router-dom';

export const ProcessHistoryStepsCard = props => {
  const { Step } = Steps;
  const navigate = useNavigate();
  const { rawData, showAssignedExpert, questionStatus } = props;
  const filteredProgressHistory =
    questionStatus?.length &&
    Object?.values(
      questionStatus?.reduce((data, itemIndex) => {
        data[itemIndex?.name] = itemIndex;
        return data;
      }, {}),
    );

  return (
    <div className="request-steps-container">
      <div className="request-submitted">
        <Steps
          direction="vertical"
          size="default"
          responsive
          progressDot={true}
          current={questionStatus && questionStatus?.length - 1}
        >
          {filteredProgressHistory &&
            filteredProgressHistory?.map((el, index) => {
              return (
                <Step
                  key={index}
                  style={{
                    minHeight:
                      showAssignedExpert && el?.id === 3 && index === 2
                        ? 'inherit'
                        : el?.id === 12 && showAssignedExpert
                          ? '130px'
                          : '65px',
                  }}
                  title={
                    <div
                      style={{ fontSize: el['name'].length > 16 ? '15px' : '16px' }}
                      className="request-submitted-steps-header"
                    >
                      {el['name']}
                      <div className="assigned-to-profile-role">
                        {formatDateTo(el?.created_at, 'MM/DD/YYYY')}
                      </div>
                      {showAssignedExpert && (el?.id === 3 || (el?.id === 12 && index === 2)) && (
                        <div
                          className="request-submitted-steps-expert-card-wrapper"
                          onClick={() => navigate(`/find-expert-detail/${rawData?.expert?.id}`)}
                        >
                          <div className="request-submitted-steps-expert-card-container">
                            <div className="assigned-to-profile-icon">
                              <img
                                style={{ borderRadius: '50%' }}
                                alt="expert"
                                height={30}
                                width={30}
                                src={
                                  rawData?.expert_meta?.avatar
                                    ? rawData?.expert_meta?.avatar
                                    : expertIcon
                                }
                              />
                            </div>
                            <div className="assigned-to-profile-detail">
                              <div className="assigned-to-profile-name">
                                {rawData?.expert?.first_name} {rawData?.expert?.last_name}
                              </div>
                              <div className="assigned-to-profile-role">
                                {rawData?.category?.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                />
              );
            })}
        </Steps>
      </div>
    </div>
  );
};
