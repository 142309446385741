import React, { useState } from 'react';
import Helmet from '../../components/common/helmet';
import AboutImg from '../../assets/images/about.png';
import LinkedInImg from '../../assets/images/linkedIn.svg';
import TwitterImg from '../../assets/images/twitter.svg';
import MailImg from '../../assets/images/mailIcon.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SOCIAL } from '../../helpers/constant';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const AboutUs = props => {
  const [ourExpert] = useState([
    {
      id: 1,
      name: 'Moataz Shokry, CPA',
      img: 'ceo-picture.png',
      position: 'Co-Founder & Chief Executive Officer',
      // linkedInURL: "https://www.linkedin.com/in/moataz-shokry",
      linkedInURL: SOCIAL.linkedIn.moataz,
      twitterURL: SOCIAL.twitter.moataz,
      email: SOCIAL.email.moataz,
    },
    {
      id: 2,
      name: 'Ahmed Ibrahim, MBA',
      img: 'cfo-picture.png',
      position: 'Co-Founder & Chief Financial Officer',
      // linkedInURL: "https://www.linkedin.com/in/ahmed-ibrahim-aa-41657163/",
      linkedInURL: SOCIAL.linkedIn.ahemad,
      twitterURL: SOCIAL.twitter.ahemad,
      email: SOCIAL.email.ahemad,
    },
    {
      id: 3,
      name: 'Abdelmaged Sayed',
      img: 'cto-picture.png',
      position: 'Chief Technology Officer',
      // linkedInURL: "https://www.linkedin.com/in/abdelmageed-sayed-364342106",
      linkedInURL: SOCIAL.linkedIn.abdul,
      twitterURL: SOCIAL.twitter.abdul,
      email: SOCIAL.email.abdul,
    },
  ]);
  return (
    <div className="padding-around about">
      <Helmet title="About Us" />
      <div className="cover-img">
        <img src={AboutImg} alt="Accountizer About Us" />
      </div>
      <div className="about-section">
        <div className="mb50">
          <p className="title">About Accountizer</p>
          <p style={{ textAlign: 'justify' }} className="sub-text">
            Accountizer is a marketplace platform built by accountants who want to change how people
            find their accounting services. We believe accounting services are not as conveniently
            accessible as they could be, so we decided to change that.
            <br />
            <br />
            We believe that the current barriers between potential clients, and professionals, may
            lead to unfavourable consequences on both sides. So we decided to take advantage of
            technology to remove these barriers and make communications between the two parties
            easier, faster, and more budget-friendly.
            <br />
            <br />
            As a result of those barriers, 81% of small- and medium-sized businesses in Canada (99%
            of the Canadian business sector) manage their books internally. Of this 81%, only 26%
            depend on internal accountants while the rest leave the work to business owners or
            managers who do not have enough accounting knowledge which qualify them to do this task
            efficiently.
            <br />
            <br />
            Only 33% of small- and medium-sized businesses in Canada are supported by accounting
            services rendered by professionals.
            <br />
            <br />
            In 2019, the Canada Revenue Agency (CRA) identified $1.185 billion from audits of small-
            and medium-sized enterprises (SMEs) .<br />
            <br />
            The average amount identified per small-sized business audit was $137,000, and per
            medium-sized business audit was $338,000, including taxes and penalties.
            <br />
            <br />
            So we believe that the existing ways of receiving accounting, tax, and business advisory
            services do not provide businesses, entrepreneurs, or individuals with an efficient
            solution. The current ways also hinder access to better business opportunities, and do
            not protect from unexpected losses, or noncompliance situations. This deficiency impacts
            individuals and businesses and hurts the entire Canadian economy.
            <br />
            <br />
            We embrace and implement the AccounTech concept, which combines the accounting industry
            with software technologies to serve accounting professionals and their clients with
            modernized solutions for automating workflows to provide more efficient and effective
            services- reduced costs!
            <br />
            <br />
            Our main goal is to spread financial literacy among more and more people. Our goal with
            Accountizer is to leave no financial question unanswered. We make finding a bookkeeping,
            accounting, tax, or business expert more effortless than ever before. Accountizer can
            get you answers to your financial queries on the spot, no matter how many, how
            frequently, or how simple.
            <br />
            <br />
            Although other accounting service solutions are available online, they are not enough to
            fill the gap in the Canadian market. These services do not:
            <br />
            <br />
            <ul>
              <li>Accompany users throughout the journey like Accountizer will.</li>
              <br />
              <li>Guide users in the professionals' selection process like Accountizer will.</li>
              <br />
              <li>
                Give their users handy and secure communication tools for contacting professionals.
              </li>
              <br />
              <li>Predetermine service price range.</li>
              <br />
              <li>
                Vet their professionals as strictly as Accountizer does. We built Accountizer for:
              </li>
              <br />
              <li>
                Startups and SMEs who seek access to on-demand, high- quality bookkeeping,
                accounting, tax, and business advisory services that also fall within budget.
              </li>
              <br />
              <li>
                Individuals who would like to smartly manage their finances, who would like to avoid
                paying more than they should during tax season, and/or those who wish to avoid tax
                penalties.
              </li>
              <br />
              <li>
                Investors who need a professional's advice before committing to investing in a
                particular business.
              </li>
              <br />
              <li>
                Decision-makers who would like to have an expert opinion before they make a
                strategic financial decision that may impact their business's financial position or
                profitability.
              </li>
              <br />
              <li>
                Executive and financial professionals who are dealing with a new subject matter.
                Those executives and professionals may not have enough training or knowledge to
                handle the new topic, leading to the need for the help of other specialized
                accounting and business advisors.
              </li>
              <br />
            </ul>
            On the other hand, Accountizer is a place built for CPAs, tax preparers, business
            advisors, or bookkeepers who run a professional practice and would like to expand their
            market share, make a more prominent online presence, overcome the fierce market
            competition, and attract new clients.
            <br />
            <br />
            It is also for those who want to start their professional practice and need help
            building client networks, and need assistance being introduced to clients interested in
            their talents so they can realize their financial independence.
            <br />
            <br />
          </p>
        </div>
        <div className="meet mt100 mb50">
          <p className="title">Meet our team</p>
          <p className="sub-text">
            Our goal is to pave the way to your success with modern, efficient, and professional
            accounting solutions.
          </p>
        </div>
        <section className="mt75">
          <Slider {...settings}>
            {ourExpert.map(item => {
              return (
                <div className="meet-cards">
                  <img
                    src={require(`../../assets/images/${item.img}`)}
                    alt="Accountizer Experts"
                  ></img>
                  <p className="mb0 mt20 title">{item.name}</p>
                  <p className="mb0 mt10 sub-text">{item.position}</p>
                  <div className="i-flex mt15">
                    <img
                      src={TwitterImg}
                      onClick={() => window.open(item.twitterURL, '_blank')}
                      className="mr10 cursor-p"
                      alt="Twitter"
                    />
                    <img
                      src={MailImg}
                      onClick={() => window.open(`mailto:${item.email}`, '_blank')}
                      className="cursor-p"
                      alt="Mail"
                      style={{ marginRight: '8px', width: '30px', height: '35px' }}
                    />
                    <img
                      src={LinkedInImg}
                      onClick={() => window.open(item.linkedInURL, '_blank')}
                      className="cursor-p"
                      alt="Linked In"
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
