import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

export const AddAnotherCard = ({ text, handleClick }) => {
  return (
    <article className="add-another-card" onClick={() => handleClick()}>
      <span>
        <PlusOutlined />
        {text}
      </span>
    </article>
  );
};
