import React from 'react';
import { Row } from 'antd';
import { dynamicMessagingCard, formatDateTo } from '../../helpers/functions';

const RightMessage = ({ m }) => {
  const { body, created_at, attachment_type } = m;

  return (
    <div className="message-container-Right">
      <Row style={{ display: 'flex', justifyContent: 'right' }}>
        <div style={{ display: 'flex', justifyContent: 'right', maxWidth: '70%' }}>
          <div>
            {dynamicMessagingCard(attachment_type, body)}
            <div className="message-time">
              <p>{formatDateTo(created_at, 'hh:mm A')}</p>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default RightMessage;
