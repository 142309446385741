import React, { useEffect, useRef, useState } from 'react';
import attachment from '../../assets/images/attachment.svg';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import LeftMessage from './leftMessageCard';
import RightMessage from './rightMessagingCard';
import {
  dynamicMessagePayloadWithAttachment,
  getUserData,
  isDocsType,
  isExcelType,
  isImageType,
  isPdfType,
  placeholderBasedOnFileType,
  role,
} from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';
import { ROLE_NAME } from '../../helpers/constant';

export const MessagingContainer = props => {
  const messageInputRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImageFile, setSelectedImageFile] = useState('');
  const [selectedFileType, setSelectedFileType] = useState('message');
  const { docURL } = useSelector(state => state.common);
  const {
    threadBySubjectId,
    isCreateNewThreadLoading,
    isImageUploadingToDBLoading,
    isPostMessageLoading,
    imageUrlFromDB,
  } = useSelector(state => state.requests);
  const dispatch = useDispatch();
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const [isUserThreadAlreadyExists, setIsUserThreadAlreadyExists] = useState(true);
  const [isUserSendingItsFirstMessage, setIsUserSendingItsFirstMessage] = useState(false);
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    // Ensure container is in view when clicking the messaging button
    messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    messageInputRef?.current?.focus();
  }, [threadBySubjectId?.messages?.length, isPostMessageLoading, messagesEndRef.current]);

  const createNewThreadUsingQid = () => {
    if (props.subjectId && threadBySubjectId?.error === 'Permission Denied') {
      setIsUserSendingItsFirstMessage(true);
    }
  };

  useEffect(() => {
    if (props.subjectId && isUserThreadAlreadyExists) {
      dispatch({ type: 'GET_THREAD_BY_SUBJECT_ID', id: props.subjectId });
      setIsUserThreadAlreadyExists(false);
    }
  }, [threadBySubjectId?.messages?.length, isUserSendingItsFirstMessage, isCreateNewThreadLoading]);

  useEffect(() => {
    if (threadBySubjectId?.messages?.length && !isUserThreadAlreadyExists) {
      const timer = setTimeout(() => {
        dispatch({ type: 'GET_THREAD_BY_SUBJECT_ID', id: props.subjectId });
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      createNewThreadUsingQid();
    }
  }, [threadBySubjectId, isLoading, isUserSendingItsFirstMessage, isCreateNewThreadLoading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [
    props.subjectId,
    props.selectedTabGroup,
    isLoading,
    isCreateNewThreadLoading,
    isUserSendingItsFirstMessage,
  ]);

  const handleImageUpload = info => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      if (isImageType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('image');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_IMAGE_TO_DB', formData: formData });
      } else if (isPdfType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('pdf');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else if (isExcelType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('excel');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else if (isDocsType(info.file.type)) {
        setSelectedImageFile(info.file.name);
        setTimeout(() => {
          setSelectedFileType('docs');
        }, 10);
        const formData = new FormData();
        formData.append('file', info.file.originFileObj);
        dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
      } else {
        setSelectedFileType('message');
      }
    }
  };

  const onMessageSend = e => {
    if (isUserThreadAlreadyExists || !threadBySubjectId?.error) {
      if (selectedFileType !== 'message' && !messageInputRef?.current?.value) {
        dispatch({
          type: 'POST_MESSAGE',
          formData: {
            id: threadBySubjectId?.id,
            message: dynamicMessagePayloadWithAttachment(selectedFileType, imageUrlFromDB, docURL),
          },
        });
        setSelectedFileType('message');
        setSelectedImageFile('');
      } else if (
        threadBySubjectId?.messages?.length &&
        selectedFileType === 'message' &&
        messageInputRef?.current?.value !== '' &&
        messageInputRef?.current?.value !== ' '
      ) {
        dispatch({
          type: 'POST_MESSAGE',
          formData: {
            id: threadBySubjectId?.id,
            message: {
              message: messageInputRef.current.value,
              has_attachment: 0,
            },
          },
        });
        messageInputRef.current.value = '';
        setSelectedImageFile('');
      }
    } else {
      dispatch({
        type: 'POST_NEW_MESSAGE_THREAD',
        formData: {
          subject: props?.subjectId,
          message: messageInputRef.current.value,
          recipients: props?.expertId,
        },
      });
      setIsUserSendingItsFirstMessage(!isUserSendingItsFirstMessage);
      setIsUserThreadAlreadyExists(!isUserThreadAlreadyExists);
      messageInputRef.current.value = '';
    }
  };

  function format(val) {
    return moment(val).format('YYYYMMDD') !== moment().format('YYYYMMDD')
      ? moment(val).format('DD MMMM')
      : 'Today';
  }

  return (
    <div ref={messageContainerRef} className="message-body">
      {props?.subjectId || isLoading ? (
        <div className="request-message-wrapper">
          {isLoading ? (
            <div
              style={{
                width: props.type === 'newDetail' ? (width * 80) / 100 : (width * 65) / 100,
                height: (height * 55) / 100,
              }}
              className="d-flex-a-c"
            >
              <Spin />
            </div>
          ) : threadBySubjectId?.messages?.length ? (
            <div className="request-message-details">
              <div
                style={{
                  height: '550px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                }}
                className="message-area"
              >
                {threadBySubjectId?.messages?.map((m, i) => {
                  const prevMessage = threadBySubjectId?.messages[i - 1];
                  const prevMessageTimeStamp = format(prevMessage?.created_at);
                  const currentMessageTimeStamp = format(m?.created_at);
                  if (getUserData().id === m?.user_id) {
                    if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                      return (
                        <>
                          {format(m?.created_at) && (
                            <div className="date">
                              <div className="message-container-date-box">
                                <p className="message-container-date-box-p">
                                  {format(m?.created_at)}
                                </p>
                              </div>
                            </div>
                          )}
                          <RightMessage m={m} />
                        </>
                      );
                    } else {
                      return <RightMessage m={m} />;
                    }
                  }
                  if (prevMessageTimeStamp !== currentMessageTimeStamp) {
                    return (
                      <>
                        {format(m?.created_at) && (
                          <div className="date">
                            <div className="message-container-date-box">
                              <p className="message-container-date-box-p">
                                {format(m?.created_at)}
                              </p>
                            </div>
                          </div>
                        )}
                        <LeftMessage
                          m={m}
                          type="container"
                          selectedThreadData={
                            role() === ROLE_NAME.CUSTOMER
                              ? threadBySubjectId?.participants?.filter(
                                  data => data.id === getUserData().id,
                                )
                              : threadBySubjectId?.participants?.filter(
                                  data => data.id !== getUserData().id,
                                )
                          }
                        />
                      </>
                    );
                  } else {
                    return (
                      <LeftMessage
                        m={m}
                        type="container"
                        selectedThreadData={
                          role() === ROLE_NAME.CUSTOMER
                            ? threadBySubjectId?.participants?.filter(
                                data => data.id !== getUserData().id,
                              )
                            : threadBySubjectId?.participants?.filter(
                                data => data.id !== getUserData().id,
                              )
                        }
                      />
                    );
                  }
                })}
                <div ref={messagesEndRef} />
              </div>
              <div className="text-box">
                <div className="icon-write">
                  {threadBySubjectId?.messages?.length ? (
                    <div className="icon">
                      <Upload
                        name="attachment"
                        accept="image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        onChange={handleImageUpload}
                        showUploadList={false}
                        maxCount={1}
                        customRequest={({ file, onSuccess }) =>
                          setTimeout(() => onSuccess('ok'), 0)
                        }
                      >
                        <img src={attachment} alt="" />
                      </Upload>
                    </div>
                  ) : null}
                  <div style={{ width: '100%' }}>
                    {selectedImageFile ? (
                      <div
                        style={{
                          backgroundColor: '#eef1f4',
                          gap: '15px',
                          width: 'max-content',
                          padding: '10px 5px',
                          borderRadius: '8px',
                          alignItems: 'center',
                        }}
                        className="d-flex"
                      >
                        <div>
                          <img
                            height={25}
                            width={25}
                            src={placeholderBasedOnFileType(selectedFileType)}
                            alt=""
                          />
                        </div>
                        <div>{selectedImageFile}</div>
                        <Button
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                          icon={<CloseCircleOutlined />}
                          size="small"
                          onClick={() => {
                            setSelectedImageFile('');
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        autoFocus
                        ref={messageInputRef}
                        required
                        className="write"
                        type="text"
                        placeholder="Write a message..."
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            onMessageSend(e);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <Button
                  onClick={e => onMessageSend(e)}
                  loading={isImageUploadingToDBLoading}
                  className="send"
                >
                  Send
                </Button>
              </div>
            </div>
          ) : (
            <div className="request-message-details">
              <div
                style={{
                  height: '550px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                }}
                className="d-flex-a-c message-area"
              >
                Please don’t hesitate to contact me if you have any questions.
              </div>
              <div className="text-box">
                <div className="icon-write">
                  {threadBySubjectId?.messages?.length ? (
                    <div className="icon">
                      <Upload
                        name="attachment"
                        accept="image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        onChange={handleImageUpload}
                        showUploadList={false}
                        maxCount={1}
                        customRequest={({ file, onSuccess }) =>
                          setTimeout(() => onSuccess('ok'), 0)
                        }
                      >
                        <img src={attachment} alt="" />
                      </Upload>
                    </div>
                  ) : null}
                  <div style={{ width: '100%' }}>
                    {selectedImageFile ? (
                      <div
                        style={{
                          backgroundColor: '#eef1f4',
                          gap: '15px',
                          width: 'max-content',
                          padding: '10px 5px',
                          borderRadius: '8px',
                          alignItems: 'center',
                        }}
                        className="d-flex"
                      >
                        <div>
                          <img
                            height={25}
                            width={25}
                            src={placeholderBasedOnFileType(selectedFileType)}
                            alt=""
                          />
                        </div>
                        <div>{selectedImageFile}</div>
                        <Button
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                          icon={<CloseCircleOutlined />}
                          size="small"
                          onClick={() => {
                            setSelectedImageFile('');
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        autoFocus={true}
                        ref={messageInputRef}
                        required
                        className="write"
                        type="text"
                        placeholder="Write a message..."
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            onMessageSend(e);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <Button
                  onClick={e => onMessageSend(e)}
                  loading={isImageUploadingToDBLoading}
                  className="send"
                >
                  Send
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : props?.type === 'detail' ? (
        <div
          style={{
            width: (width * 55) / 100,
            height: (height * 80) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <div
          style={{
            width: (width * 55) / 100,
            height: (height * 80) / 100,
            backgroundColor: 'white',
          }}
          className="d-flex-a-c"
        >
          Please select one of the chatroom.
        </div>
      )}
    </div>
  );
};
