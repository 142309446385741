import React, { useState } from 'react';

export default function RadioButtonImage({ setCurrentValue, buttonList = [] }) {
  const [checked, setChecked] = useState();
  const handleCheck = e => {
    setChecked(e.target.value);
    setCurrentValue(e.target.value);
  };
  return (
    <ul className="radio-button-image-list">
      {buttonList.map((buttonItem, index) => {
        return (
          <li key={index}>
            <input
              type="radio"
              name={`Radio Button - ${buttonItem.value}`}
              id={`${index}-${buttonItem.value}`}
              value={buttonItem.value}
              checked={checked === buttonItem.value}
              onChange={e => handleCheck(e)}
            />
            <label htmlFor={`${index}-${buttonItem.value}`}>
              <img alt={`Radio Button - ${buttonItem.value}`} src={buttonItem.img} />
            </label>
            <span>{buttonItem.label}</span>
          </li>
        );
      })}
    </ul>
  );
}
